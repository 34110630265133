import React from 'react';
import "./Card.scss";
import {CardProps} from "../../../plugins/interfaces/card";

function Card({
                  title = '',
                  description = '',
                  backgroundColor = '',
                  alignItemsCard = '',
                  widthCard = "",
                  heightCard = "",
                  colorTitle = "",
                  fontSizeTitle = "",
                  fontWeightTitle = 0,
                  fontWeightContent = 0,
                  colorContent = "",
                  fontSizeContent = "",
                  textAlign,
                  textAlignContent,
                  handleMouseEnter,
                  handleMouseLeave,
                  onClickCard,
                  children
              }: CardProps) {

    return (
        <div className="card"
             style={{
                 alignItems: alignItemsCard,
                 backgroundColor: backgroundColor,
                 height: heightCard,
                 width: widthCard
             }}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onClick={onClickCard}
        >
            {children}
            <div className="card-wrapper space-y-2">
                <p style={{
                    color: colorTitle,
                    fontSize: fontSizeTitle,
                    fontWeight: fontWeightTitle,
                    textAlign: textAlign
                }}>{title}</p>
                <p style={{
                    color: colorContent,
                    fontSize: fontSizeContent,
                    fontWeight: fontWeightContent,
                    textAlign: textAlignContent
                }}>{description}</p>
            </div>
        </div>
    );
}

export default Card;
