import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import "./DigitalSignature.scss";
import {burgerBlack, logoPeentar} from "../../../assets/svgs";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import Navbar from "../../../components/atoms/Navbar";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import LazyImage from "../../../components/atoms/LazyImage";
import digitalSignature from "../../../assets/svgs/products/digital-signature/digital_signature.png";
import bgDigitalSignature from "../../../assets/svgs/products/digital-signature/background_digital_signature.png";
import healthcareService from "../../../assets/svgs/products/digital-signature/healthcare_services.png";
import hospitalSystem from "../../../assets/svgs/products/digital-signature/digital_signature2.png";
import electronicSignature from "../../../assets/svgs/products/digital-signature/electronic_signature.png";
import advDigitalSignature from "../../../assets/svgs/products/digital-signature/advantage_digital_signature.png";
import document from "../../../assets/svgs/products/digital-signature/document.png";
import medicalRecord from "../../../assets/svgs/products/digital-signature/electronic_MR.png";
import validity from "../../../assets/svgs/products/digital-signature/validity.png";
import identity from "../../../assets/svgs/products/digital-signature/identity.png";
import legal from "../../../assets/svgs/products/digital-signature/legal.png";
import business from "../../../assets/svgs/products/digital-signature/business.png";
import leftCane from "../../../assets/svgs/products/digital-signature/left_cane_white.png";
import rightCane from "../../../assets/svgs/products/digital-signature/right_cane_white.png";
import key from "../../../assets/svgs/products/digital-signature/key.png";
import orangeCane from "../../../assets/svgs/products/digital-signature/orange_cane.png";
import integrity from "../../../assets/svgs/products/digital-signature/integrity.png";
import authenticity from "../../../assets/svgs/products/digital-signature/authenticity.png";
import nonRepudiation from "../../../assets/svgs/products/digital-signature/non_repudiation.png";
import bgDigitalSignatureMobile from "../../../assets/svgs/products/digital-signature/bg_digital_signature_mobile.png";
import bgEncryptionMobile from "../../../assets/svgs/products/digital-signature/bg_encryption.png";
import Card from "../../../components/atoms/Card/Card";
import Footer from "../../../components/atoms/Footer";

function DigitalSignature() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const [isActiveHealthcare, setIsActiveHealthcare] = useState(false);
    const [isActiveHospitalSystem, setIsActiveHospitalSystem] = useState(false);
    const [isActiveElectronicSignature, setIsActiveElectronicSignature] = useState(false);
    const [isActiveDocument, setIsActiveDocument] = useState(false);
    const [isActiveMedicalRecord, setIsActiveMedicalRecord] = useState(false);

    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }
    return (
        <div onScroll={scrollEvent}
             className="digitalSignature-page-wrapper">
            <div className="digitalSignature-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <NavbarProduct
                    handleNavigateMenuProduct={handleNavigateMenuProduct}
                />
                <div className="digitalSignature-header-content">
                    <div className="basis-2/3 content-1">
                        <div className="title-wrapper">
                            <LazyImage src={digitalSignature} alt={"digital-signature"}/>
                            <p className="title">Digital Signature</p>
                        </div>
                        <div className="bg-digital-signature-mobile-wrapper">
                            <LazyImage src={bgDigitalSignatureMobile}
                                       alt={"bg-digital-signature-mobile"}/>
                        </div>
                        <div className="text-wrapper">
                            <p className="text1">Implementation of Digital
                                Signatures in Medical Records</p>
                            <p className="text2">The use of digital signatures in medical records is a
                                big step forward in healthcare. It means doctors and nurses can sign
                                important documents electronically instead of using pen and paper.
                                This makes things faster and more secure. It also helps ensure
                                that patient information stays safe and that hospitals follow all the
                                rules. With digital signatures, healthcare workers can focus more on taking care
                                of patients, making sure they get the right treatment at the right time.
                            </p>
                        </div>
                    </div>
                    <div className="basis-1/3">
                        <LazyImage src={bgDigitalSignature} alt={"bg-digital-signature"}
                                   className="bg-digital-signature"
                        />
                    </div>
                </div>
            </div>

            <div className="digitalSignature-container-1-wrapper">
                <p className="title">Integrated TTE Module With SIM RS</p>
                <p className="description">We've made a simple but big change: we connected TTE scans to our SIM RS
                    system. Now, doctors can quickly check TTE results right in our digital system. It means
                    faster treatment decisions for heart patients and smoother hospital
                    operations overall. This upgrade helps us give better care to our patients.</p>

                <div className="digital-signature-card-wrapper">
                    {isActiveHealthcare ?
                        <Card title={"MANAGING HEALTHCARE SERVICES"}
                              description={"The process of healthcare services, documented in Electronic " +
                                  `Medical Records using the Hospital Information System (SIM RS):\n` +
                                  "a. Written & Signed by Medical Professionals.\n" +
                                  "b. Stakeholders: Patients & Insurance Companies."}
                              backgroundColor={"#3895DE"}
                              alignItemsCard={"start"}
                              heightCard={"26em"}
                              widthCard={"24em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveHealthcare(!isActiveHealthcare)}
                              handleMouseEnter={() => setIsActiveHealthcare(true)}
                              handleMouseLeave={() => setIsActiveHealthcare(false)}>
                        </Card> :
                        <Card title={"MANAGING HEALTHCARE SERVICES"}
                              description={"The process of healthcare services, " +
                                  "documented in Electronic Medical Record..."}
                              backgroundColor={"#3895DE"}
                              alignItemsCard={"start"}
                              heightCard={"26em"}
                              widthCard={"24em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveHealthcare(!isActiveHealthcare)}
                              handleMouseEnter={() => setIsActiveHealthcare(true)}
                              handleMouseLeave={() => setIsActiveHealthcare(false)}>
                            <LazyImage src={healthcareService} alt={"card-img"}/>
                        </Card>}

                    {isActiveHospitalSystem ?
                        <Card title={"DIGITAL SIGNATURE IN HOSPITAL SYSTEM"}
                              description={"Electronic Medical Records documents (PDF files) are signed " +
                                  "using an Integrated Digital Signature Module within the Hospital " +
                                  "Information System (Documents remain within the Hospital premises)."}
                              backgroundColor={"#32A0D5"}
                              alignItemsCard={"start"}
                              heightCard={"26em"}
                              widthCard={"24em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveHospitalSystem(!isActiveHospitalSystem)}
                              handleMouseEnter={() => setIsActiveHospitalSystem(true)}
                              handleMouseLeave={() => setIsActiveHospitalSystem(false)}>
                        </Card> :
                        <Card title={"DIGITAL SIGNATURE IN HOSPITAL SYSTEM"}
                              description={"Electronic Medical Records documents (PDF files) " +
                                  "are signed using an Integrated..."}
                              backgroundColor={"#32A0D5"}
                              alignItemsCard={"start"}
                              heightCard={"26em"}
                              widthCard={"24em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveHospitalSystem(!isActiveHospitalSystem)}
                              handleMouseEnter={() => setIsActiveHospitalSystem(true)}
                              handleMouseLeave={() => setIsActiveHospitalSystem(false)}>
                            <LazyImage src={hospitalSystem} alt={"card-img"}/>
                        </Card>}

                    {isActiveElectronicSignature ?
                        <Card title={"INTEGRATING WITH TTE ELECTRONIC SIGNATURE"}
                              description={"The DiBAC Module hashes and signs to the Electronic " +
                                  "Signature Service (TTE) within the Hospital Information System."}
                              backgroundColor={"#24BAC0"}
                              alignItemsCard={"start"}
                              heightCard={"26em"}
                              widthCard={"24em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveElectronicSignature(!isActiveElectronicSignature)}
                              handleMouseEnter={() => setIsActiveElectronicSignature(true)}
                              handleMouseLeave={() => setIsActiveElectronicSignature(false)}>
                        </Card> :
                        <Card title={"INTEGRATING WITH TTE ELECTRONIC SIGNATURE"}
                              description={"The DiBAC Module hashes and signs to the Electronic " +
                                  "Signature Service (TTE) wi...."}
                              backgroundColor={"#24BAC0"}
                              alignItemsCard={"start"}
                              heightCard={"26em"}
                              widthCard={"24em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveElectronicSignature(!isActiveElectronicSignature)}
                              handleMouseEnter={() => setIsActiveElectronicSignature(true)}
                              handleMouseLeave={() => setIsActiveElectronicSignature(false)}>
                            <LazyImage src={electronicSignature} alt={"card-img"}/>
                        </Card>}

                    {isActiveDocument ?
                        <Card title={"ENSURING DOCUMENT INTEGRITY"}
                              description={"The output from the Electronic Signature System service " +
                                  "is a hash-code of the Medical Record Document with the applied " +
                                  "Electronic Signature, which is then incorporated into the PDF " +
                                  "file structure according to ISO32000 standards."}
                              backgroundColor={"#1ACBB2"}
                              alignItemsCard={"start"}
                              textAlign={"end"}
                              textAlignContent={"start"}
                              heightCard={"27em"}
                              widthCard={"30em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveDocument(!isActiveDocument)}
                              handleMouseEnter={() => setIsActiveDocument(true)}
                              handleMouseLeave={() => setIsActiveDocument(false)}>
                        </Card> :
                        <Card title={"ENSURING DOCUMENT INTEGRITY"}
                              description={"The output from the Electronic Signature System service " +
                                  "is a hash-code of the Medical Record Document with the applied Electronic S..."}
                              backgroundColor={"#1ACBB2"}
                              alignItemsCard={"end"}
                              textAlign={"end"}
                              textAlignContent={"end"}
                              heightCard={"27em"}
                              widthCard={"30em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveDocument(!isActiveDocument)}
                              handleMouseEnter={() => setIsActiveDocument(true)}
                              handleMouseLeave={() => setIsActiveDocument(false)}>
                            <LazyImage src={document} alt={"card-img"}/>
                        </Card>}

                    {isActiveMedicalRecord ?
                        <Card title={"ARCHIVING SIGNED ELECTRONIC MEDICAL RECORD"}
                              description={"Electronic Medical Records documents with applied " +
                                  "Electronic Signatures can be archived for distribution as needed."}
                              backgroundColor={"#0DD7AC"}
                              alignItemsCard={"start"}
                              heightCard={"27em"}
                              widthCard={"30em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveMedicalRecord(!isActiveMedicalRecord)}
                              handleMouseEnter={() => setIsActiveMedicalRecord(true)}
                              handleMouseLeave={() => setIsActiveMedicalRecord(false)}>
                        </Card> :
                        <Card title={"ARCHIVING SIGNED ELECTRONIC MEDICAL RECORD"}
                              description={"Electronic Medical Records documents with applied Electronic " +
                                  "Signatures can be archived for distribution as needed."}
                              backgroundColor={"#0DD7AC"}
                              alignItemsCard={"start"}
                              heightCard={"27em"}
                              widthCard={"30em"}
                              colorTitle={"#FFFFFF"}
                              fontSizeTitle={"1.2em"}
                              fontWeightTitle={700}
                              fontWeightContent={200}
                              colorContent={"#FFFFFF"}
                              fontSizeContent={"1em"}
                              onClickCard={() => setIsActiveMedicalRecord(!isActiveMedicalRecord)}
                              handleMouseEnter={() => setIsActiveMedicalRecord(true)}
                              handleMouseLeave={() => setIsActiveMedicalRecord(false)}>
                            <LazyImage src={medicalRecord} alt={"card-img"}/>
                        </Card>}
                </div>
            </div>

            <div className="digitalSignature-container-2-wrapper">
                <p className="title">Advantages of Digital Signatures</p>
                <p className="description">Digital signatures bring efficiency and security to document handling.
                    They streamline processes, reduce costs, and enable remote transactions,
                    making them indispensable in today's digital era.</p>
                <div className="digital-signature-content-wrapper">
                    <div className="basis-2/3 space-y-3">
                        <div className="list-item-wrapper">
                            <div className="item-1">
                                <LazyImage src={validity} alt={"validity"}/>
                                <p>Ensuring Information Validity</p>
                            </div>
                            <div className="item-2">
                                <ul className="list-disc">
                                    <li>Encryption on electronically certified documents ensures the validity <br/>
                                        of the information within.
                                    </li>
                                    <li>Even the smallest changes to the document can be easily detected.</li>
                                    <li>The information contained within the document becomes irrefutable.</li>
                                </ul>
                            </div>
                        </div>

                        <div className="list-item-wrapper">
                            <div className="item-1">
                                <LazyImage src={legal} alt={"legal"}/>
                                <p>Identity Verification of Owners</p>
                            </div>
                            <div className="item-2">
                                <ul className="list-disc">
                                    <li>Owner Identity Verification The identity of the electronic certificate <br/>
                                        holder is verified by PSrE.
                                    </li>
                                    <li>3 Security Factors for verification: What you KNOW, What you HAVE, <br/>
                                        What you ARE.
                                    </li>
                                    <li>Identity: Self-declaration of personal data, ID card, telephone
                                        number, email, <br/> Biometrics (fingerprint, face, etc.).
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="list-item-wrapper">
                            <div className="item-1">
                                <LazyImage src={identity} alt={"identity"}/>
                                <p>Legal Authority</p>
                            </div>
                            <div className="item-2">
                                <ul className="list-disc">
                                    <li>Electronic Documents: UU No. 11 of 2008 - Electronic Information
                                        and <br/> Transactions.
                                    </li>
                                    <li>Electronic Signatures: PP No. 71 of 2019 - Management of
                                        Electronic <br/> Systems and Transactions.
                                    </li>
                                    <li>Medical Records: Minister of Health Regulation
                                        No. PMK 269 of 2008 - <br/> Medical Records.
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="list-item-wrapper">
                            <div className="item-1">
                                <LazyImage src={business} alt={"business"}/>
                                <p>Business Optimization</p>
                            </div>
                            <div className="item-2">
                                <ul className="list-disc">
                                    <li>Cost efficiency of paper-based documents (paper costs, printing costs, <br/>
                                        distribution costs, archive space costs).
                                    </li>
                                    <li>The dissemination and circulation processes of documents are
                                        not <br/> constrained by space and time.
                                    </li>
                                    <li>The essence of documents remains accountable
                                        using Electronic Signature <br/> mechanisms.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="basis-1/3 adv-digital-signature">
                        <LazyImage src={advDigitalSignature} alt={"adv-digital-signature"}/>
                    </div>
                </div>
            </div>

            <div className="digitalSignature-container-3-wrapper">
                <div className="content-left">
                    <LazyImage src={key} alt={"key"}/>
                </div>
                <div className="content-right">
                    <div className="left-cane">
                        <LazyImage src={leftCane} alt={"left-cane"}/>
                    </div>
                    <p className="text1">Validating Information <br/> Integrity with Encryption</p>
                    <p className="text2">In the digital realm, maintaining information validity is vital.
                        Encryption, especially in digitally certified documents, plays a crucial role in this process.
                        By encrypting data, these documents are shielded from unauthorized
                        alterations. Even minor changes trigger alerts, ensuring the information
                        remains trustworthy. Consequently, digitally certified documents provide a
                        reliable and irrefutable source of information, reinforcing confidence in their
                        accuracy and integrity.</p>

                    <div className="right-cane">
                        <LazyImage src={rightCane} alt={"right-cane"}/>
                    </div>
                </div>

                <div className="content-right-mobile">
                    <div className="left-cane-mobile">
                        <LazyImage src={leftCane} alt={"left-cane"}/>
                    </div>
                    <div className="text-wrapper-mobile">
                        <p className="text1">Validating Information <br/> Integrity with Encryption</p>
                        <p className="text2">In the digital realm, maintaining information validity is vital.
                            Encryption, especially in digitally certified documents, plays a crucial role in this
                            process. By encrypting data, these documents are shielded from unauthorized
                            alterations. Even minor changes trigger alerts, ensuring the information
                            remains trustworthy. Consequently, digitally certified documents provide a reliable and
                            irrefutable source of information, reinforcing confidence in their
                            accuracy and integrity.</p>
                    </div>

                    <div className="right-cane-mobile">
                        <LazyImage src={rightCane} alt={"right-cane"}/>
                    </div>
                </div>
            </div>

            <div className="digitalSignature-container-4-wrapper">
                <div className="orange-cane-img">
                    <LazyImage src={orangeCane} alt={"orange-cane"}/>
                </div>
                <div className="title-section">
                    <p className="title">Secure & Trusted</p>
                </div>
                <div className="section">
                    <LazyImage src={integrity} alt={"integrity"}/>
                    <div className="text-wrapper">
                        <p className="text1">Integrity</p>
                        <p className="text2">Digital Signature ensures integrity, <br/>
                            providing users with trustworthy <br/>
                            and reliable experiences.</p>
                    </div>
                </div>
                <div className="section">
                    <LazyImage src={authenticity} alt={"authenticity"}/>
                    <div className="text-wrapper">
                        <p className="text1">Authenticity</p>
                        <p className="text2">Digital Signature guarantees <br/> authenticity, ensuring that your <br/>
                            documents are legitimate and secure.</p>
                    </div>
                </div>
                <div className="section">
                    <LazyImage src={nonRepudiation} alt={"non-repudiation"}/>
                    <div className="text-wrapper">
                        <p className="text1">non-Repudiation</p>
                        <p className="text2">That users cannot deny their <br/> actions or signatures, providing a <br/>
                            secure and reliable authentication <br/> process.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
        ;
}

export default DigitalSignature;
