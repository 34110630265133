import React, {useState} from 'react';
import Navbar from "../../../components/atoms/Navbar";
import "./CorporateValue.scss";
import Card from "../../../components/atoms/Card/Card";
import {
    city,
    collaboration,
    customer, greenCane,
    innovation,
    iotIA,
    orangeCane,
    outlet,
    pioneeringProgress,
    talkWithUs
} from "../../../assets/svgs/about";
import {
    burgerBlack,
    knowUsMobile,
    logoPeentar,
    pioneeringMobile,
    vectorImg
} from "../../../assets/svgs";
import Footer from "../../../components/atoms/Footer";
import RegularButton from "../../../components/atoms/RegularButton";
import {useNavigate} from "react-router-dom";
import {
    activeNavbarJobDetailStyle,
    activeNavbarWhiteJobDetailStyle,
    fontMenuAboutStyle,
    fontMenuAboutStyleActive,
    menuProductStyle,
    navbarJobDetailStyle,
    navbarStyleWhite,
} from "../../../plugins/constant/navbar-styles.constant";
import LazyImage from "../../../components/atoms/LazyImage";
import {useScroll} from "../../../components/atoms/ScrollProvider";
import corporateValueVector from "../../../assets/svgs/about/corporate_value_vector.png"
import personAi from "../../../assets/svgs/about/person_ai.png"
import corporateValueIcon from "../../../assets/svgs/about/corporate_value_icon.png"
import corporateValueVectorMobile from "../../../assets/svgs/about/corporate_value_vector_mobile.png"
import personFutureMobile from "../../../assets/svgs/about/person_future_mobile.png"

function CorporateValue() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const targetRef = useScroll();

    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }

    return (
        <div onScroll={scrollEvent}
             className="about-page-wrapper scroll-smooth">
            <div className="about-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarJobDetailStyle : activeNavbarWhiteJobDetailStyle}
                    buttonStyle={changeNavbar ? navbarJobDetailStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? menuProductStyle : navbarStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? fontMenuAboutStyle : fontMenuAboutStyleActive}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="corporateValue-header-content-mobile">
                    <LazyImage
                        src={corporateValueVectorMobile}
                        alt={"corporate-value-vector-mobile"}
                        className="corporate-value-vector-mobile"
                    />
                    <div className="person-future-mobile-wrapper">
                        <LazyImage
                            src={personFutureMobile}
                            alt={"person-future-mobile"}
                            className="person-future-mobile"
                        />
                    </div>
                </div>

                <div className="corporateValue-header-content">
                    <div className="content-left">
                        <LazyImage
                            src={corporateValueVector}
                            alt={"corporate-value-vector"}
                            className="corporate-value-vector"
                        />
                        <div className="person-ai-wrapper">
                            <LazyImage
                                src={personAi}
                                alt={"person-ai"}
                                className="person-ai"
                            />
                        </div>
                    </div>
                    <div className='content-right'>
                        <div className="title-wrapper">
                            <LazyImage src={corporateValueIcon} alt="corporate-value-icon"/>
                            <p className="title">Forging Tomorrow</p>
                        </div>
                        <div className="text-wrapper">
                            <p className="text1">Innovating the Blend of IoT and AI for Connectivity</p>
                            <p className="text2">Our company focuses on IoT and AI technologies and
                                aims to shape a smart future. We believe combining IoT and AI can
                                transform how we interact with the world. We're dedicated
                                to creating innovative solutions that enhance customer satisfaction
                                and boost business efficiency and sustainability.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-content-1">
                <div className="about-content-1-wrapper">
                    <div className="absolute top-0 right-0">
                        <LazyImage
                            src={orangeCane}
                            alt={"orange-cane"}
                            className={"orange-cane"}/>
                    </div>
                    <p className="p1">Empowering Business Excellence</p>
                    <p className="p2">Building an Integrated IoT and AI Ecosystem for <br/>
                        Enhanced Customer Experiences</p>
                    <div className="absolute start-0">
                        <LazyImage
                            src={greenCane}
                            alt={"green-cane"}
                            className={"green-cane"}
                        />
                    </div>
                </div>
            </div>

            <div className="about-content-2-wrapper">
                <Card title={"Enhancing Customer Interactions"}
                      description={"We envision a world where every object is connected " +
                          "and interacts intelligently to provide better " +
                          "experiences for your customers"}
                      backgroundColor={"#3895DE"}
                      alignItemsCard={"center"}
                      heightCard={"28em"}
                      widthCard={"21em"}
                      colorTitle={"#FFFFFF"}
                      fontSizeTitle={"1.4em"}
                      fontWeightTitle={500}
                      colorContent={"#FFFFFF"}
                      fontSizeContent={"1.1em"}
                >
                    <LazyImage src={customer}
                               alt={"card-img"}
                               className="margin-card"/>
                </Card>
                <Card title={"Our Commitment to Innovation"}
                      description={"We are committed to moving towards a more efficient, connected and sustainable business ecosystem through the technology solutions we offer"}
                      backgroundColor={"#32A0D5"}
                      alignItemsCard={"center"}
                      heightCard={"28em"}
                      widthCard={"21em"}
                      colorTitle={"#FFFFFF"}
                      fontSizeTitle={"1.4em"}
                      fontWeightTitle={500}
                      colorContent={"#FFFFFF"}
                      fontSizeContent={"1.1em"}
                >
                    <LazyImage src={innovation}
                               alt={"card-img"}
                               className="margin-card"/>
                </Card>
                <Card title={"Advancing IoT and AI Solutions"}
                      description={"The realization of this is to continue developing and perfecting IoT and AI-based technology to provide reliable and market-leading solutions and significant added value to our customers"}
                      backgroundColor={"#24BAC0"}
                      alignItemsCard={"center"}
                      heightCard={"28em"}
                      widthCard={"21em"}
                      colorTitle={"#FFFFFF"}
                      fontSizeTitle={"1.4em"}
                      fontWeightTitle={500}
                      colorContent={"#FFFFFF"}
                      fontSizeContent={"1.1em"}
                >
                    <LazyImage src={iotIA}
                               alt={"card-img"}
                               className="margin-card"/>
                </Card>
                <Card title={"Fostering Cross-Sector Collaboration"}
                      description={"We also continue to strive to create an open ecosystem that enables cross-sector collaboration and"}
                      backgroundColor={"#1ACBB2"}
                      alignItemsCard={"center"}
                      heightCard={"28em"}
                      widthCard={"21em"}
                      colorTitle={"#FFFFFF"}
                      fontSizeTitle={"1.4em"}
                      fontWeightTitle={500}
                      colorContent={"#FFFFFF"}
                      fontSizeContent={"1.1em"}
                >
                    <LazyImage src={collaboration}
                               alt={"card-img"}
                               className="margin-card"/>
                </Card>
            </div>

            <div className="about-content-3">
                <div className="box-1">
                    <p className="p1">Fostering Innovation</p>
                    <p className="p2">Innovation, Integrity, Collaboration: Driving <br/> Technological Impact</p>
                    <p className="p3">We instill core values such as continuous innovation, integrity, and strategic
                        partnerships in the company's operations. We understand that technology development requires
                        a holistic approach and close collaboration with partners, customers, and communities. With
                        these values, we believe that our company can have a profound positive impact on
                        technological development and society as a whole.</p>
                </div>

                <div className="box-2">
                    <div className="box-content">
                        <div className="section-1">
                            <p className="amount-city">37</p>
                            <p className="city">City</p>
                        </div>
                        <LazyImage src={city} alt={"city"}/>
                        <div className="absolute top-0 right-0">
                            <LazyImage src={vectorImg} alt={"vector_img"}/>
                        </div>
                    </div>

                    <div className="box-content">
                        <div className="section-2">
                            <p className="amount-outlet">1804</p>
                            <p className="outlet">Outlet</p>
                        </div>
                        <LazyImage src={outlet} alt={"outlet"}/>
                        <div className="absolute top-0 right-0">
                            <LazyImage src={vectorImg} alt={"vector_img"}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-content-4">
                <LazyImage
                    src={pioneeringProgress}
                    alt={"pioneering-progress"}
                    width={"100%"}/>
            </div>

            <div className="about-content-4-mobile">
                <LazyImage
                    src={pioneeringMobile}
                    alt={"pioneering-mobile"}
                    width={"100%"}/>
            </div>

            <div className="about-content-5-wrapper">
                <LazyImage src={talkWithUs} alt={"talk-with-us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="about-content-5-mobile-wrapper">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CorporateValue;
