import React, {SVGProps, useRef} from 'react';
import "./ScrollCard.scss"

function IcBaselineArrowBackIos(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
            <path fill="#888888" d="M11.67 3.87L9.9 2.1L0 12l9.9 9.9l1.77-1.77L3.54 12z"></path>
        </svg>
    )
}
function IcBaselineArrowForwardIos(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
            <path fill="#8DA1AB" d="M6.23 20.23L8 22l10-10L8 2L6.23 3.77L14.46 12z"></path>
        </svg>
    )
}

type ScrollCardType = {
    children: React.ReactNode
    styleButtonArrowLeft?: string,
    styleButtonArrowRight?: string
}

function ScrollCard({children, styleButtonArrowLeft, styleButtonArrowRight}: ScrollCardType) {
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const scroll = (scrollOffset: number) => {
        if (scrollRef.current) {
            scrollRef.current.scrollLeft += scrollOffset;
        }
    };

    const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
        const card = scrollRef.current;
        if (!card) return;
        card.dataset.isDown = 'true';
        card.dataset.startX = (e.pageX - card.offsetLeft).toString();
        card.dataset.scrollLeft = card.scrollLeft.toString();
    };
    const onMouseLeave = () => {
        const card = scrollRef.current;
        if (!card) return;
        card.dataset.isDown = 'false';
    };

    const onMouseUp = () => {
        const card = scrollRef.current;
        if (!card) return;
        card.dataset.isDown = 'false';
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        const card = scrollRef.current;
        if (!card) return;
        if (card.dataset.isDown !== 'true') return;
        e.preventDefault();
        const x = e.pageX - card.offsetLeft;
        const startX = parseInt(card.dataset.startX || '0', 10);
        const walk = (x - startX) * 3; // scroll-fast
        card.scrollLeft = parseInt(card.dataset.scrollLeft || '0', 10) - walk;
    };

    return (
        <div className="scrolling-card-container">
            <div className={`arrow-left-btn ${styleButtonArrowLeft}`}>
                <button onClick={() => scroll(-300)} className="scroll-button">
                    <IcBaselineArrowBackIos/>
                </button>
            </div>
            <div
                className="scrolling-card space-x-6"
                ref={scrollRef}
                onMouseDown={onMouseDown}
                onMouseLeave={onMouseLeave}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
            >
                {children}
            </div>
            <div className={`arrow-right-btn ${styleButtonArrowRight}`}>
                <button onClick={() => scroll(300)} className="scroll-button">
                    <IcBaselineArrowForwardIos/>
                </button>
            </div>
        </div>
    );
}

export default ScrollCard;
