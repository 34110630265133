import React from 'react';
import "./Service.scss";
import {advancedImg, bgImgAbout, bgLinearRightTop, iconService, relevantImg, securedImg} from "../../../../assets/svgs";
import LazyImage from "../../../atoms/LazyImage";

function Service() {
    return (
        <div className="service-container-wrapper">
            <p className="service-title">Service</p>

            <div className="bg-linear-right-top-mobile">
                <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
            </div>

            <div className="service-container">
                <div className="section-left">
                    <div className="icon-service-wrapper">
                        <LazyImage src={bgImgAbout} alt={"bg-about"}/>
                        <div className="absolute -top-5 left-8">
                            <LazyImage src={iconService} alt={"icon-service"}/>
                        </div>
                    </div>
                </div>
                <div className="section-right">
                    <div className="description-service-wrapper">
                        <p className="service-text">Managed services provide comprehensive, managed
                            solutions to companies' information technology needs, allowing them to
                            focus on business growth while increasing operational efficiency and security.
                            By utilizing managed services, companies can free up their internal
                            resources to focus on core business activities that support growth and
                            innovation, while the technical and operational aspects are taken care
                            of by the service provider.</p>
                    </div>
                </div>
            </div>

            <div className="item-img-service">
                <div className="img-service">
                    <LazyImage src={relevantImg} alt={"relevant-img"}/>
                    <p>Relevant</p>
                </div>
                <div className="img-service">
                    <LazyImage src={securedImg} alt={"secured-img"}/>
                    <p>Secured</p>
                </div>
                <div className="img-service">
                    <LazyImage src={advancedImg} alt={"advanced-img"}/>
                    <p>Advanced</p>
                </div>
            </div>
        </div>
    );
}

export default Service;
