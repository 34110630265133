import React, {useState} from 'react';
import "./OmniChannel.scss";
import {useNavigate} from "react-router-dom";
import Navbar from "../../../components/atoms/Navbar";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import LazyImage from "../../../components/atoms/LazyImage";
import {
    bgLinearLeftBottom,
    bgLinearRightBottom,
    bgLinearRightTop, burgerBlack,
    knowUsMobile,
    logoPeentar
} from "../../../assets/svgs";
import bgLaptopBag from "../../../assets/svgs/products/omnichannel/bg_laptop_bags.png";
import omniChannel from "../../../assets/svgs/products/omnichannel/omni_channel.png";
import Card from "../../../components/atoms/Card/Card";
import consistency from "../../../assets/svgs/products/omnichannel/consistency.png";
import integration from "../../../assets/svgs/products/omnichannel/integration.png";
import customer from "../../../assets/svgs/products/omnichannel/customer_understanding.png";
import flexibility from "../../../assets/svgs/products/omnichannel/flexibility.png";
import responsive from "../../../assets/svgs/products/omnichannel/responsive.png";
import aisle from "../../../assets/svgs/products/omnichannel/endless_aisle.png";
import inventory from "../../../assets/svgs/products/omnichannel/bg_inventory.png";
import inventoryMobile from "../../../assets/svgs/products/omnichannel/bg_inventory_mobile.png";
import Footer from "../../../components/atoms/Footer";
import {talkWithUs} from "../../../assets/svgs/about";
import RegularButton from "../../../components/atoms/RegularButton";
import {useScroll} from "../../../components/atoms/ScrollProvider";

function OmniChannel() {
    const navigate = useNavigate();
    const targetRef = useScroll();
    const [changeNavbar, setChangeNavbar] = useState(false);

    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }

    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }

    return (
        <div onScroll={scrollEvent}
             className="omniChannel-page-wrapper">
            <div className="omniChannel-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="omniChannel-header-content-wrapper">
                    <NavbarProduct
                        handleNavigateMenuProduct={handleNavigateMenuProduct}
                    />
                    <div className="omniChannel-header-content">
                        <div className="omniChannel-title-mobile-wrapper">
                            <LazyImage src={omniChannel} alt={"omni-channel"}/>
                            <p className="omniChannel-title-mobile">Omni Channel</p>
                        </div>
                        <div className="section-1">
                            <LazyImage src={bgLaptopBag} alt={"bg-laptop-bag"}/>
                        </div>
                        <div className="section-2">
                            <div className="omniChannel-title-wrapper">
                                <LazyImage src={omniChannel} alt={"omni-channel"}/>
                                <p className="omniChannel-title">Omni Channel</p>
                            </div>
                            <p className="text1">Consistent experience to <br/> customers across multiple <br/>
                                communication channels</p>
                            <p className="text2">Omni Channel systems are designed to provide a consistent
                                experience to <br/>
                                customers across multiple communication channels and ways of interacting. <br/>
                                This means that customers can interact through multiple channels, such as
                                physical <br/>
                                outlets, websites, mobile apps, social media, or contact centers, and their <br/>
                                experiences will be coordinated and integrated across those channels.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="omniChannel-container-1-wrapper">
                <p className="title-container">The Omni Channel system provides several main functions</p>

                <div className="bg-linear-right-top">
                    <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
                </div>

                <div className="omniChannel-content-card">
                    <Card title={"Consistency"}
                          description={"Omni-channel systems aim to provide consistency " +
                              "in message, appearance and experience across all channels. " +
                              "Customer information and context is shared in real-time " +
                              "between channels, so customers can continue their " +
                              "interactions seamlessly without losing previous " +
                              "data or experiences."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={consistency} alt={"card-img"}/>
                    </Card>
                    <Card title={"Integration"}
                          description={"Different channels in an omni-channel system are connected and integrated synergistically. Customer data, inventory, purchase history and other information can be accessed simultaneously across channels, enabling customers to have a seamless and connected experience, for example initiating a purchase online and completing it at a physical outlet."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.925em"}>
                        <LazyImage src={integration} alt={"card-img"}/>
                    </Card>
                    <Card title={"Customer understanding"}
                          description={"Omni Channel systems leverage customer data and analytics to understand their preferences, habits, and needs across each channel. With a better understanding of customers, businesses can provide more relevant and personalized experiences that suit their desires."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={customer} alt={"card-img"}/>
                    </Card>
                    <Card title={"Flexibility"}
                          description={"Omni Channel System allows customers to choose their preferred channels and switch between Sales Channels easily. For example, customers can start searching for a product on a website, continue through a mobile app, and purchase it at a physical outlet, without losing existing information or experiences."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={flexibility} alt={"card-img"}/>
                    </Card>
                    <Card title={"Responsive"}
                          description={"Omni Channel systems allow businesses to respond quickly and efficiently to customers across multiple channels. In this case, real-time communication and responsive customer support are important to provide a satisfying experience and build customer trust."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={responsive} alt={"card-img"}/>
                    </Card>
                </div>

                <div className="bg-linear-right-bottom">
                    <LazyImage src={bgLinearRightBottom} alt={"linear-bg"}/>
                </div>

                <div className="bg-linear-left-bottom">
                    <LazyImage src={bgLinearLeftBottom} alt={"linear-bg"}/>
                </div>
            </div>

            <div className="omniChannel-container-1-mobile-wrapper">
                <p className="title-container-mobile">The Omni Channel system provides several main functions</p>

                <div className="bg-linear-right-top">
                    <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
                </div>

                <div className="omniChannel-content-card-mobile">
                    <Card title={"Consistency"}
                          description={"Omni-channel systems aim to provide consistency " +
                              "in message, appearance and experience across all channels. " +
                              "Customer information and context is shared in real-time " +
                              "between channels, so customers can continue their " +
                              "interactions seamlessly without losing previous " +
                              "data or experiences."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={consistency} alt={"card-img"}/>
                    </Card>
                    <Card title={"Integration"}
                          description={"Different channels in an omni-channel system are connected and integrated synergistically. Customer data, inventory, purchase history and other information can be accessed simultaneously across channels, enabling customers to have a seamless and connected experience, for example initiating a purchase online and completing it at a physical outlet."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.925em"}>
                        <LazyImage src={integration} alt={"card-img"}/>
                    </Card>
                    <Card title={"Customer understanding"}
                          description={"Omni Channel systems leverage customer data and analytics to understand their preferences, habits, and needs across each channel. With a better understanding of customers, businesses can provide more relevant and personalized experiences that suit their desires."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={customer} alt={"card-img"}/>
                    </Card>
                    <Card title={"Flexibility"}
                          description={"Omni Channel System allows customers to choose their preferred channels and switch between Sales Channels easily. For example, customers can start searching for a product on a website, continue through a mobile app, and purchase it at a physical outlet, without losing existing information or experiences."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={flexibility} alt={"card-img"}/>
                    </Card>
                    <Card title={"Responsive"}
                          description={"Omni Channel systems allow businesses to respond quickly and efficiently to customers across multiple channels. In this case, real-time communication and responsive customer support are important to provide a satisfying experience and build customer trust."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"34em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={700}
                          fontWeightContent={200}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={responsive} alt={"card-img"}/>
                    </Card>
                </div>

                <div className="bg-linear-left-bottom">
                    <LazyImage src={bgLinearLeftBottom} alt={"linear-bg"}/>
                </div>
            </div>

            <div className="omniChannel-container-2-wrapper">
                <div className="section-1">
                    <LazyImage src={aisle} alt={"endless-aisle"}/>
                </div>
                <div className="section-2">
                    <p className="text1">Endless Aisle</p>
                    <p className="text2">The "Endless Aisle" concept in an omni-channel
                        system refers to a strategy that allows customers to access and
                        purchase products that are not always available in physical stores,
                        but can be found through online channels. The idea behind it is
                        to give customers access to an “endless aisle” of products and
                        options, surpassing the inventory limitations that exist in a
                        physical store. Endless Aisle aims to enhance customer experience
                        by providing easy access to a wide range of products and variants,
                        while providing flexibility in purchasing methods.
                    </p>
                    <p className="text3">With Endless Aisle, physical stores are
                        no longer a major limitation for inventory. Customers can
                        browse and purchase products that may not be physically
                        available in the store, but can be accessed via online platforms.
                        Customers can easily switch between different channels (physical stores,
                        websites, mobile apps) to browse and purchase products. If customers
                        find a product in “Endless Aisle” that is not available in a physical
                        store, they can usually order that product and choose a method of order
                        fulfillment, such as home delivery, in-store pickup, or delivery from a
                        distribution center.
                    </p>
                </div>
            </div>

            <div className="omniChannel-container-3-wrapper">
                <LazyImage src={inventory} alt={"inventory"} width={"100%"}/>
                <div className="absolute">
                    <p className="text1"> This concept gives <br/> customers a wider choice.</p>
                    <p className="text2">They can search for products, colors, sizes or other variants online,
                        even if physical <br/> stores are limited in terms of inventory.</p>
                </div>
            </div>

            <div className="omniChannel-container-3-mobile-wrapper">
                <LazyImage src={inventoryMobile} alt={"bg-inventory-mobile"} width={"100%"}/>
            </div>

            <div className="omniChannel-container-4-wrapper">
                <LazyImage src={talkWithUs} alt={"talk with us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="omniChannel-container-4-mobile-wrapper">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default OmniChannel;
