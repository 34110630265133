import React from 'react';
import {
    bgImgAbout, bigData,
    customerLoyalty, digitalSignature,
    iconAbout,
    integratedHealthCare,
    omnichannel,
    pos,
    smartStock
} from "../../../../assets/svgs";
import RegularButton from "../../../atoms/RegularButton";
import "./About.scss";
import {useNavigate} from "react-router-dom";
import CardItem from "../../../atoms/CardItem";
import ScrollCard from "../../../atoms/ScrollCard";
import LazyImage from "../../../atoms/LazyImage";

function About() {
    const navigate = useNavigate();
    return (
        <>
            <div className="about-container">
                <div className="section-left">
                    <div className="icon-about-wrapper">
                        <LazyImage src={bgImgAbout} alt={"bg-about"} height={"100%"} width={"100%"}/>
                        <div className="absolute bottom-4 left-12">
                            <LazyImage src={iconAbout} alt={"icon-about"} height={"93%"} width={"93%"}/>
                        </div>
                    </div>
                </div>
                <div className="section-right">
                    <div className="description-about-wrapper">
                        <p className="about-title">About Peentar</p>
                        <p className="about-text">Our company focuses on IoT and AI technologies and
                            aims to shape a smart future. We believe combining IoT and AI can transform
                            how we interact with the world. We're dedicated <br/>
                            to creating innovative solutions that enhance customer satisfaction and boost business
                            efficiency and sustainability.</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#1AA596"}
                            hoverColor={"#1AA596"}
                            fontColor={"#FFFFFF"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            fontSize={"1em"}
                            iconColor={"#FFFFFF"}
                            onClick={() => navigate("/about/corporate-profile")}
                            label={"Know More"}/>
                    </div>
                </div>
            </div>

            <div className="product-container">
                <p className="product-title">Product</p>
                <ScrollCard
                    styleButtonArrowLeft={"absolute top-60 -left-5"}
                    styleButtonArrowRight={"absolute top-60 -right-7"}
                >
                    <CardItem
                        backgroundColor={"#1393FF"}
                        product={smartStock}
                        titleProduct={"Smartstock"}
                        contentProduct={"Dynamic Cloud-Integrated POS Solution"}
                        navigateToMenuProduct={() => navigate("/product/smart-stock")}
                    />
                    <CardItem
                        backgroundColor={"#24BAC0"}
                        product={pos}
                        titleProduct={"POS"}
                        contentProduct={"Revolutionizing Supply Chain Efficiency with IoT and AI Solutions"}
                        navigateToMenuProduct={() => navigate("/product/pos")}
                    />
                    <CardItem
                        backgroundColor={"#32A0D5"}
                        product={omnichannel}
                        titleProduct={"OmniChannel"}
                        contentProduct={"Consistent experience to customers across multiple communication channels"}
                        navigateToMenuProduct={() => navigate("/product/omni-channel")}
                    />
                    <CardItem
                        backgroundColor={"#0DD7AC"}
                        product={integratedHealthCare}
                        titleProduct={"Integrated Healthcare"}
                        contentProduct={"An integrated healthcare system is a holistic approach " +
                            "to providing health services"}
                        navigateToMenuProduct={() => navigate("/product/integrated-healthcare")}
                    />
                    <CardItem
                        backgroundColor={"#08A859"}
                        product={digitalSignature}
                        titleProduct={"Digital Signature"}
                        contentProduct={"Implementation of Digital Signatures in Medical Records"}
                        navigateToMenuProduct={() => navigate("/product/digital-signature")}
                    />
                    <CardItem
                        backgroundColor={"#1ACBB2"}
                        product={customerLoyalty}
                        titleProduct={"Customer Loyalty"}
                        contentProduct={"Utilized to manage the relationship between businesses and customers"}
                        navigateToMenuProduct={() => navigate("/product/customer-loyalty")}
                    />
                    <CardItem
                        backgroundColor={"#0AB773"}
                        product={bigData}
                        titleProduct={"Big Data"}
                        contentProduct={"Collecting, processing, and analyzing data on a large scale " +
                            "using specialized tools, techniques, and methods"}
                        navigateToMenuProduct={() => navigate("/product/big-data")}
                    />
                </ScrollCard>
            </div>
        </>
    );
}

export default About;
