import React, {SVGProps} from 'react';
import LazyImage from "../../atoms/LazyImage";
import graphImg from "../../../assets/svgs/carreer/graph_img.png";
import RegularButton from "../../atoms/RegularButton";
import "./JobVacancies.scss";
import {JobVacanciesProps} from "../../../plugins/interfaces/jobVacanciesInterface";

function IcBaselineLocationOn(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
            <path fill="#888888"
                  d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5"></path>
        </svg>
    )
}



function JobVacancies({jobName, jobTitle, role, location, handleJobDetail}: JobVacanciesProps) {
    return (
        <div className="item-job">
            <div className="flex justify-end">
                <LazyImage src={graphImg} alt={"graph-img"}/>
            </div>

            <div className="section">
                <div className="content-1">
                    <p>{role}</p>
                    <div className="job-location-role">
                        <IcBaselineLocationOn/>
                        <span className="text-location">{location}</span>
                    </div>
                </div>

                <div className="content-2">
                    <p className="title-job">{jobTitle}</p>
                    <div className="job-location-role">
                        <span className="text-role">{jobName}</span>
                    </div>
                </div>

                <hr/>

                <div className="content-3">
                    <RegularButton
                        heightButton={"3em"}
                        widthButton={"12em"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"2em"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1em"}
                        onClick={handleJobDetail}
                        label={"Details"}/>
                </div>
            </div>
        </div>
    );
}

export default JobVacancies;
