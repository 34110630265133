export const testimonials = [
    {
        id: 1,
        quote: 'During my time at PT. Harmonix Teknologi Peentar, what I experienced the most ' +
            'was a work culture that truly fosters collaboration and creativity with other teams ' +
            'and with the leaders. Continuously gaining detailed knowledge of business ' +
            'processes and projects with challenging timelines in the healthcare ecosystem ' +
            'was crucial in developing my skills.',
        image: require('../../assets/svgs/testimonials/wahyudin.png'),
        backgroundColor: '#3895DE',
        name: 'Wahyudin',
        role: 'Product Developer',
    },
    {
        id: 2,
        quote: 'Working at PT Harmonix Teknologi Peentar has been an incredible experience for me. ' +
            'The warm and family-like work environment makes me feel valued and supported. ' +
            'Colleagues here respect each other and collaborate, creating a positive ' +
            'and productive work atmosphere. The company also places a strong ' +
            'emphasis on technological advancement.',
        image: require('../../assets/svgs/testimonials/aan _nazmudin.png'),
        backgroundColor: '#24BAC0',
        name: 'Aan Nazmudin',
        role: 'Developer',
    },
    {
        id: 3,
        quote: 'When the first time I\'m join in this company, I saw that vision and ' +
            'mission were very good for the current era of technological development, ' +
            'especially in the IT healthcare. Then I see in this company have a healthy ' +
            'work environment, starting from the relationship between employees who ' +
            'work together in a healthy manner for the sustainability of the company.',
        image: require('../../assets/svgs/testimonials/adytio_prayogo.png'),
        backgroundColor: '#1ACBB2',
        name: 'Adytio Prayogo',
        role: 'System Support',
    },
    {
        id: 4,
        quote: 'I gained a lot of healthcare knowledge, skills and experience during my ' +
            'time working here. Great place to work and develop skill communication.',
        image: require('../../assets/svgs/testimonials/noor_rachma.png'),
        backgroundColor: '#3895DE',
        name: 'Noor Rachma Dwi A',
        role: 'System Support',
    },
    {
        id: 5,
        quote: 'I am grateful for the opportunity to serve as a Database Administrator ' +
            'in this company. This experience has greatly enhanced my skills in managing, ' +
            'optimizing, and securing the company\'s database systems, which are critical ' +
            'in supporting operational efficiency and business growth.',
        image: require('../../assets/svgs/testimonials/profile_avatar.svg').default,
        backgroundColor: '#24BAC0',
        name: 'Putra Kautsar',
        role: 'Database Engineer',
    },
    {
        id: 6,
        quote: 'I\'ve been working as a DevOps engineer for a few years, and I really enjoy ' +
            'the working flexibility. Moreover, the team is always supportive and helpful ' +
            'when we have challenges, and management has always been supportive ' +
            'in guiding the employees.',
        image: require('../../assets/svgs/testimonials/profile_avatar.svg').default,
        backgroundColor: '#1ACBB2',
        name: 'Arif Rosada',
        role: 'Devops Engineer',
    },
    {
        id: 7,
        quote: 'Working at \'Harmonix Teknologi Peentar\' is a process of delivering solutions, ' +
            'innovations, and digitalization, and it is an endless learning process. A valuable ' +
            'process for creating fresh ideas in the competitive and fast-growing era of ' +
            'the digital world in order to be able to adapt so as not to be eroded by competition.',
        image: require('../../assets/svgs/testimonials/azis_amirulbahar.png'),
        backgroundColor: '#3895DE',
        name: 'Azis Amirul Bahar',
        role: 'Android Developer',
    },
    {
        id: 8,
        quote: '"Peentar", as an experienced IT company, has created exploration space for me to develop ' +
            'my skills that I have, which is very good for my career development. Of course, ' +
            '"Peentar" has many experts and professionals in their fields, it means clients ' +
            'will feel satisfied with the output produced. ',
        image: require('../../assets/svgs/testimonials/qodir_masruri.png'),
        backgroundColor: '#24BAC0',
        name: 'Qodir Mashuri',
        role: 'iOS Developer',
    },
    {
        id: 9,
        quote: 'Working at Harmonix Teknologi Peentar has been an incredible journey! ' +
            'It\'s a fun and dynamic place where I get to explore new technologies and expand my ' +
            'knowledge in best practices every day. The company fosters a great team culture, ' +
            'encouraging collaboration and continuous learning. The supportive environment ' +
            'and innovative projects make every day exciting and fulfilling.',
        image: require('../../assets/svgs/testimonials/syakur_rahman_.png'),
        backgroundColor: '#1ACBB2',
        name: 'Syakur Rahman',
        role: 'Software Engineer',
    },
    {
        id: 10,
        quote: 'I’ve had the pleasure of working at Harmonix Teknologi Peentar for the past five years, ' +
            'and it has been an incredible journey. The innovative environment and supportive team ' +
            'culture have allowed me to grow both professionally and personally. ' +
            'At Harmonix, we’re at the forefront of technological advancements, ' +
            'which makes every project exciting and challenging.',
        image: require('../../assets/svgs/testimonials/profile_avatar.svg').default,
        backgroundColor: '#3895DE',
        name: 'Fauzi ',
        role: 'Software Engineer',
    },
    {
        id: 11,
        quote: 'Company that has matured over a long journey, but there is still plenty of room ' +
            'for improvement if we want to reach our full potential.',
        image: require('../../assets/svgs/testimonials/profile_avatar.svg').default,
        backgroundColor: '#24BAC0',
        name: 'Erwin Sitorus',
        role: 'Software Engineer',
    },
    {
        id: 12,
        quote: 'During my tenure at Harmonix Teknologi Peentar as a Java Backend Developer, ' +
            'I had the opportunity to be part of a dynamic and innovative team that specialized ' +
            'in healthcare IT solutions. This experience was invaluable in shaping my professional ' +
            'growth and deepening my understanding of both backend development and ' +
            'the healthcare industry.',
        image: require('../../assets/svgs/testimonials/harry.png'),
        backgroundColor: '#1ACBB2',
        name: 'Harry Rosadi',
        role: 'Software Engineer',
    }
]
