import React, {createContext, useRef, useContext, ReactNode, MutableRefObject} from 'react';

type ScrollContextType = MutableRefObject<HTMLDivElement | null>;

const ScrollContext = createContext<ScrollContextType | null>(null);

interface ScrollProviderProps {
    children: ReactNode;
}

export const ScrollProvider: React.FC<ScrollProviderProps> = ({children}) => {
    const targetRef = useRef<HTMLDivElement | null>(null);

    return (
        <ScrollContext.Provider value={targetRef}>
            {children}
        </ScrollContext.Provider>
    );
};

export const useScroll = (): ScrollContextType => {
    const context = useContext(ScrollContext);
    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider');
    }
    return context;
};
