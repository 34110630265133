import React, {SVGProps, useRef, useState} from 'react';
import "./JobDetail.scss";
import {useNavigate, useParams} from "react-router-dom";
import {burgerBlack, burgerWhite, logoPeentar, logoPeentarWhite} from "../../../assets/svgs";
import {
    activeNavbarJobDetailStyle,
    activeNavbarWhiteJobDetailStyle,
    navbarJobDetailStyle,
    navbarWhiteJobDetailStyle,
} from "../../../plugins/constant/navbar-styles.constant";
import Navbar from "../../../components/atoms/Navbar";
import {jobs} from "../../../plugins/data/jobDescription";
import JobVacancies from "../../../components/organisms/JobVacancies/index.ts";
import Footer from "../../../components/atoms/Footer";

function IcBaselineLocationOn(props: SVGProps<SVGSVGElement>) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}>
            <path fill="#FFFFFF"
                  d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7m0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5"></path>
        </svg>
    )
}

function JobDetail() {
    const navigate = useNavigate();
    const targetRef = useRef<HTMLDivElement | null>(null);
    const [changeNavbar, setChangeNavbar] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const {jobId}: any = useParams<{ jobId: string }>();
    const dataJobs = jobs.find(job => job.id === parseInt(jobId));

    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const handleJobDetail = (jobId: number) => {
        navigate(`/job-details/${jobId}`);
        targetRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (scrollRef.current) {
            setIsDown(true);
            scrollRef.current.classList.add('active');
            setStartX(e.pageX - scrollRef.current.offsetLeft);
            setScrollLeft(scrollRef.current.scrollLeft);
        }
    };

    const mouseLeaveHandler = () => {
        setIsDown(false);
        if (scrollRef.current) {
            scrollRef.current.classList.remove('active');
        }
    };

    const handleApplyToEmail = () => {
        setTimeout(() => {
            window.location.href = 'mailto:HR.Recruitment@teknologipeentar.com';
        }, 1000);
    };

    const mouseUpHandler = () => {
        setIsDown(false);
        if (scrollRef.current) {
            scrollRef.current.classList.remove('active');
        }
    };

    const mouseMoveHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isDown) return;
        e.preventDefault();
        if (scrollRef.current) {
            const x = e.pageX - scrollRef.current.offsetLeft;
            const walk = (x - startX) * 3;
            scrollRef.current.scrollLeft = scrollLeft - walk;
        }
    };

    return (
        <div onScroll={scrollEvent}
             className="job-detail-page-wrapper scroll-smooth">
            <div ref={targetRef} className="job-detail-header-page-wrapper bg-cover bg-center">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={changeNavbar ? logoPeentar : logoPeentarWhite}
                    activeButtonStyle={changeNavbar ? activeNavbarJobDetailStyle : activeNavbarWhiteJobDetailStyle}
                    buttonStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle}
                    iconNavbar={changeNavbar ? burgerBlack : burgerWhite}
                    fontMenuProductStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle}
                    fontMenuAboutStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle }
                    svgFillColor={changeNavbar ? "#1AA596" : "#FFFFFF"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="job-detail-header-content-wrapper">
                    <p className="text-role">{dataJobs?.role}</p>
                    <div className="location-wrapper">
                        <IcBaselineLocationOn/>
                        <span>Jakarta & Surakarta</span>
                    </div>
                </div>
            </div>

            <div className="job-detail-container-1-wrapper">
                <p className="title">Job Description</p>
                <ul className="list-disc px-6">
                    {dataJobs?.descJob?.map(job => (
                        <li>{job}</li>
                    ))}
                </ul>
                <hr/>
                <p className="title">Additional Information</p>
                <div className="item-wrapper-column">
                    <div className="item-wrapper-col1">
                        <div className="item">
                            <p className="text-item">Job Level</p>
                            <p>{dataJobs?.jobLevel}</p>
                        </div>
                        <div className="item">
                            <p className="text-item">Work Experience</p>
                            <p>{dataJobs?.workExperience}</p>
                        </div>
                        <div className="item">
                            <p className="text-item">Job Specialization</p>
                            <p>{dataJobs?.jobSpecialization}</p>
                        </div>
                    </div>

                    <div className="item-wrapper-col2">
                        <div className="item">
                            <p className="text-item">Qualifications</p>
                            <p>{dataJobs?.qualifications}</p>
                        </div>
                        <div className="item">
                            <p className="text-item">Job Type</p>
                            <p>{dataJobs?.jobType}</p>
                        </div>
                    </div>
                </div>
                <hr/>
                <p className="title">Company Information</p>
                <div className="item-wrapper-column">
                    <div className="item-wrapper-col1">
                        <div className="item">
                            <p className="text-item">Company Size</p>
                            <p>{dataJobs?.companySize}</p>
                        </div>
                        <div className="item">
                            <p className="text-item">Industry</p>
                            <p>{dataJobs?.industry}</p>
                        </div>
                        <div className="button-wrapper">
                            <button onClick={handleApplyToEmail}>Apply</button>
                        </div>
                    </div>

                    <div className="item-wrapper-col2">
                        <div className="item">
                            <p className="text-item">Application Processing Time</p>
                            <p>{dataJobs?.time}</p>
                        </div>
                        <div className="item">
                            <p className="text-item">Working Days</p>
                            <p>{dataJobs?.workingDays}</p>
                        </div>
                        <div className="button-mobile-wrapper">
                            <button onClick={handleApplyToEmail}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="job-detail-container-2-wrapper">
                <p className="title">Other Job Vacancies</p>
                <div className="content-card-wrapper">
                    <div
                        ref={scrollRef}
                        onMouseDown={mouseDownHandler}
                        onMouseLeave={mouseLeaveHandler}
                        onMouseUp={mouseUpHandler}
                        onMouseMove={mouseMoveHandler}
                        className="scrolling-card">
                        <div className="card-content">
                            {jobs.map(job => (
                                <JobVacancies
                                    key={job?.id}
                                    role={job?.role}
                                    location={"Jakarta & Surakarta"}
                                    jobTitle={"Job Specialization"}
                                    jobName={job?.jobSpecialization}
                                    handleJobDetail={() => handleJobDetail(job?.id)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default JobDetail;
