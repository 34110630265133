import React, {useState} from 'react';
import "./ClientPage.scss";
import {burgerBlack, burgerWhite, logoPeentar, logoPeentarWhite} from "../../assets/svgs";
import {
    activeNavbarJobDetailStyle,
    activeNavbarWhiteJobDetailStyle,
    menuProductStyle,
    menuProductStyleWhite,
    navbarJobDetailStyle,
    navbarWhiteJobDetailStyle
} from "../../plugins/constant/navbar-styles.constant";
import Navbar from "../../components/atoms/Navbar";
import {useNavigate} from "react-router-dom";
import {kfKlinikLab, kimiaFarma, kimiaFarmaMobile, marvee, orangeCane, telkomSigma} from "../../assets/svgs/client";
import Footer from "../../components/atoms/Footer";
import LazyImage from "../../components/atoms/LazyImage";

function ClientPage() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    return (
        <div onScroll={scrollEvent} className="client-page-wrapper scroll-smooth">
            <div className="client-header-page bg-cover bg-center">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={changeNavbar ? logoPeentar : logoPeentarWhite}
                    activeButtonStyle={changeNavbar ? activeNavbarJobDetailStyle : activeNavbarWhiteJobDetailStyle}
                    buttonStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle}
                    svgFillColor={changeNavbar ? "#1AA596" : "#FFFFFF"}
                    svgFillColorMobile={"#FFFFFF"}
                    iconNavbar={changeNavbar ? burgerBlack : burgerWhite}
                    fontMenuProductStyle={changeNavbar ? menuProductStyle : menuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="client-header-content-wrapper">
                    <div className='orange-cane-img-mobile'>
                        <div className="orange-cane-img">
                            <LazyImage src={orangeCane} alt={"orange-cane"}/>
                        </div>
                    </div>

                    <div className="client-header-content">
                        <p className="title-header">Global Commitment to <br/> Exceptional Client Service</p>
                        <p className="text-header">We are very committed to providing the best service for all
                            clients throughout the world.</p>
                    </div>
                </div>
            </div>

            <div className="client-content-1-wrapper">
                <p className="text-1">Our Trusted Partners</p>
                <p className="text-2">The following are our clients who have entrusted <br/>
                    their products and services to help their business</p>
                <div className="img-clients">
                    <LazyImage src={kimiaFarma} alt={"kimia-farma"}/>
                    <LazyImage src={kimiaFarmaMobile} alt={"kimia-farma-mobile"}/>
                    <LazyImage src={kfKlinikLab} alt={"kf-klinik-lab"}/>
                    <LazyImage src={marvee} alt={"marvee"}/>
                    <LazyImage src={telkomSigma} alt={"telkom-sigma"}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default ClientPage;
