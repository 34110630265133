import React from 'react';
import './App.css';
import HomePage from "./pages/HomePage";
import {BrowserRouter as Router, Routes, Route, Outlet} from 'react-router-dom';
import ClientPage from "./pages/ClientPage";
import ServicePage from "./pages/ServicePage";
import OmniChannel from "./pages/Product/OmniChannel";
import Smartstock from "./pages/Product/Smartstock";
import Pos from "./pages/Product/Pos";
import IntegratedHealthcare from "./pages/Product/IntegratedHealthcare";
import DigitalSignature from "./pages/Product/DigitalSignature";
import CustomerLoyalty from "./pages/Product/CustomerLoyalty";
import BigData from "./pages/Product/BigData";
import CareerPage from "./pages/CareerPage";
import JobDetail from "./pages/CareerPage/JobDetail";
import CorporateValue from "./pages/AboutPage/CorporateValue";
import PeentarValue from "./pages/AboutPage/PeentarValue";
import {ScrollProvider} from "./components/atoms/ScrollProvider";

function App() {
    const renderLayout = () => {
        return (
            <div>
                <Outlet/>
            </div>
        )
    }

    return (
        <ScrollProvider>
            <Router>
                <Routes>
                    <Route path='/' element={<HomePage/>}/>
                    <Route path='/product' element={renderLayout()}>
                        <Route path='/product/smart-stock' element={<Smartstock/>}/>
                        <Route path='/product/pos' element={<Pos/>}/>
                        <Route path='/product/omni-channel' element={<OmniChannel/>}/>
                        <Route path='/product/integrated-healthcare' element={<IntegratedHealthcare/>}/>
                        <Route path='/product/digital-signature' element={<DigitalSignature/>}/>
                        <Route path='/product/customer-loyalty' element={<CustomerLoyalty/>}/>
                        <Route path='/product/big-data' element={<BigData/>}/>
                    </Route>
                    <Route path='/about' element={renderLayout()}>
                        <Route path='/about/corporate-profile' element={<CorporateValue/>}/>
                        <Route path='/about/peentar-value' element={<PeentarValue/>}/>
                    </Route>
                    <Route path='/client-page' element={<ClientPage/>}/>
                    <Route path='/service-page' element={<ServicePage/>}/>
                    <Route path='/career-page' element={<CareerPage/>}/>
                    <Route path='/job-details/:jobId' element={<JobDetail/>}/>
                    <Route path='*' element={<HomePage/>}/>
                </Routes>
            </Router>
        </ScrollProvider>
    );
}

export default App;
