import React, {useState} from 'react';
import Navbar from "../../../components/atoms/Navbar";
import {useNavigate} from "react-router-dom";
import "./Smartstock.scss";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import {burgerBlack, knowUsMobile, logoPeentar} from "../../../assets/svgs";
import RegularButton from "../../../components/atoms/RegularButton";
import Footer from "../../../components/atoms/Footer";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import LazyImage from "../../../components/atoms/LazyImage";
import {
    demandPlanning,
    empoweringCompanies,
    maximingBuyingPower, orangeCane,
    smartstock, vector
} from "../../../assets/svgs/products/smartstock";
import {talkWithUs} from "../../../assets/svgs/about";
import smarstockHeroImg from "../../../assets/svgs/products/smartstock/smarstock_hero_img.png";
import smarstockMobileImg from "../../../assets/svgs/products/smartstock/smarstock_hero_mobile.png";
import {useScroll} from "../../../components/atoms/ScrollProvider";

function SmartstockPage() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const targetRef = useScroll();
    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }
    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }

    return (
        <div onScroll={scrollEvent} className="smartstock-page-wrapper">
            <div className="smartstock-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="smartstock-header-content-wrapper">
                    <NavbarProduct
                        handleNavigateMenuProduct={handleNavigateMenuProduct}/>
                    <div className="smartstock-header-content">
                        <div className="smarstock-text1-wrapper">
                            <LazyImage src={smartstock} alt={"smartstok"}
                                       className="smartstock"/>
                            <p>Smartstock</p>
                        </div>
                        <div className="smarstock-text2-wrapper">
                            <LazyImage src={smarstockMobileImg}
                                       alt={"smarstock_mobile_img"}
                                       className={"smarstock_mobile_img"}/>
                            <p className="text1">Revolutionizing Supply Chain Efficiency <br/>
                                with IoT and AI Solutions</p>
                            <LazyImage src={smarstockHeroImg}
                                       alt={"smarstock_hero_img"}
                                       className={"smarstock_hero_img"}
                                       width={"100%"}/>
                            <p className="text2">In today's dynamic business
                                landscape, our IoT and AI-powered supply chain solutions
                                revolutionize efficiency by optimizing processes, minimizing risks, and enhancing
                                responsiveness to market changes. By continuously monitoring supply and demand and
                                leveraging AI insights, our systems empower businesses to streamline operations
                                and proactively mitigate risks. With automated recommendations for optimizing routes
                                and
                                managing stock levels, we drive efficiency and resilience across the supply chain.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="smartstock-container-1">
                <div className="section-1">
                    <p className="text-1">Maximizing Buying Power</p>
                    <p className="text-2">Efficient Procurement for <br/> Business Success</p>
                    <p className="text-3">Leveraging buying power enhances business strategies, promoting
                        efficiency and <br/> profitability by enabling large-scale purchases with favorable supplier
                        terms, fostering <br/> stable relationships and streamlining procurement through
                        Smartstock's
                        Integrate <br/> Supply Chain Management.</p>
                </div>
                <div className="section-2">
                    <LazyImage src={maximingBuyingPower} alt={"maximing-buying-power"} width={"100%"}/>
                </div>
                <div className="absolute bottom-0 left-0">
                    <LazyImage src={vector} alt={"vector"}/>
                </div>
            </div>

            <div className="smartstock-container-2">
                <div className="section-1">
                    <LazyImage src={demandPlanning} alt={"demand_planning"}/>
                </div>
                <div className="section-2">
                    <p className="text-1">Demand Planning</p>
                    <p className="text-2">Optimizing Customer <br/> Satisfaction with Demand <br/> Management</p>
                    <p className="text-3">Understanding and managing customer demands effectively boosts
                        satisfaction by <br/> ensuring products are available as expected. Demand planning
                        aids in accurate forecasts, <br/> facilitating production and inventory adjustments
                        to prevent overstocking or shortages. Accurate forecasting optimizes inventory
                        levels, minimizing costs and sales risks.</p>
                </div>
            </div>

            <div className="smartstock-container-3">
                <div className="section-1">
                    <p className="text-1">Empowering Companies</p>
                    <p className="text-2">AI-driven Market Prediction <br/> and Inventory Optimization</p>
                    <p className="text-3">AI, through machine learning, analyzes extensive data to
                        predict market trends and <br/> demand fluctuations, empowering companies to
                        optimize inventory levels and make <br/> informed decisions in response to
                        changing market dynamics.</p>
                </div>
                <div className="section-2">
                    <LazyImage src={empoweringCompanies} alt={"empowering_companies"}/>
                </div>
                <div className="orange-cane-img">
                    <LazyImage src={orangeCane} alt={"orange-cane"}/>
                </div>
            </div>

            <div className="smartstock-container-4">
                <LazyImage src={talkWithUs} alt={"talk-with-us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="smartstock-container-mobile-4">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default SmartstockPage;
