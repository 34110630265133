import React, {useState} from 'react';
import "./CareerPage.scss";
import Navbar from "../../components/atoms/Navbar";
import {burgerBlack, logoPeentar} from "../../assets/svgs";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../plugins/constant/navbar-styles.constant";
import {useNavigate} from "react-router-dom";
import LazyImage from "../../components/atoms/LazyImage";
import backgroundCareer from "../../assets/svgs/carreer/background_career.png";
import Card from "../../components/atoms/Card/Card";
import JobVacancies from "../../components/organisms/JobVacancies/index.ts";
import ScrollCard from "../../components/atoms/ScrollCard";
import members from "../../assets/svgs/carreer/members.png";
import health from "../../assets/svgs/carreer/health.png";
import marriage from "../../assets/svgs/carreer/marriage.png";
import compensation from "../../assets/svgs/carreer/compensation.png";
import subsidies from "../../assets/svgs/carreer/subsidies.png";
import events from "../../assets/svgs/carreer/routine_events.png";
import cane from "../../assets/svgs/carreer/orange_cane.png";
import circle from "../../assets/svgs/carreer/orange_circle.png";
import bgCareerMobile from "../../assets/svgs/carreer/bg_career_mobile.png";
import Footer from "../../components/atoms/Footer";
import {jobs} from "../../plugins/data/jobDescription";
import {testimonials} from "../../plugins/data/testimonials";

function CareerPage() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const handleJobDetail = (jobId: number) => {
        navigate(`/job-details/${jobId}`);
    };

    return (
        <div onScroll={scrollEvent} className="career-page-wrapper scroll-smooth">
            <div className="career-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    iconNavbar={burgerBlack}
                    fontMenuProductStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="career-content-wrapper">
                    <LazyImage src={backgroundCareer} alt={"background_career"}/>
                </div>

                <div className="career-content-mobile-wrapper">
                    <LazyImage src={bgCareerMobile} alt={"background_career_mobile"}/>
                </div>
            </div>

            <div className="career-container-1-wrapper space-y-6">
                <div className="grid grid-rows-2 grid-flow-col gap-6">
                    {jobs.map(job => (
                        <JobVacancies
                            key={job?.id}
                            role={job?.role}
                            location={"Jakarta & Surakarta"}
                            jobTitle={"Job Specialization"}
                            jobName={job?.jobSpecialization}
                            handleJobDetail={() => handleJobDetail(job?.id)}
                        />
                    ))}
                </div>
            </div>

            <div className="career-container-1-mobile-wrapper">
                <div className="career-content-card">
                    {jobs.map(job => (
                        <JobVacancies
                            key={job?.id}
                            role={job?.role}
                            location={"Jakarta & Surakarta"}
                            jobTitle={"Job Specialization"}
                            jobName={job?.jobSpecialization}
                            handleJobDetail={() => handleJobDetail(job?.id)}
                        />
                    ))}
                </div>
            </div>

            <div className="career-container-2-wrapper">
                <div className="text-wrapper">
                    <p className="title">Rewards Await New Joiners</p>
                    <p className="description">We offer competitive salaries, health benefits, and opportunities for
                        career
                        growth. Join our team today and start enjoying these perks</p>
                </div>
                <ScrollCard
                    styleButtonArrowLeft={"absolute top-30 -left-5"}
                    styleButtonArrowRight={"absolute top-30 -right-7"}
                >
                    <Card title={"Member Get Member"}
                          description={"Do you have friends who are suitable for " +
                              "joining Buana? Give a recommendation, you can get a bonus!"}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"24em"}
                          widthCard={"22em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={members} alt={"card-img"}/>
                    </Card>

                    <Card title={"Health benefits"}
                          description={"Starting from dental care, outpatient care, " +
                              "inpatient care, surgery, and more for you and your family."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"24em"}
                          widthCard={"22em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={health} alt={"card-img"}/>
                    </Card>

                    <Card title={"Marriage Allowance"}
                          description={"Don't worry, every employee who plans to get married gets an allowance too."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"24em"}
                          widthCard={"22em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={marriage} alt={"card-img"}/>
                    </Card>

                    <Card title={"Grief Compensation"}
                          description={"There is grief compensation for those who have " +
                              "experienced a disaster or lost a family member."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"24em"}
                          widthCard={"22em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={compensation} alt={"card-img"}/>
                    </Card>

                    <Card title={"Maternity Subsidies"}
                          description={"We are also happy for the birth of a child in " +
                              "your family. There are childbirth subsidies too."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"24em"}
                          widthCard={"22em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={subsidies} alt={"card-img"}/>
                    </Card>

                    <Card title={"Routine Events"}
                          description={"Buana likes to have fun regular events, usually every week or once a month."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"24em"}
                          widthCard={"22em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.3em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}>
                        <LazyImage src={events} alt={"card-img"}/>
                    </Card>
                </ScrollCard>
            </div>

            <div className="career-container-3-wrapper">
                <div className="cane-img-wrapper">
                    <LazyImage src={cane} alt={"cane"}/>
                </div>
                <div className="text-wrapper">
                    <p className="title">Hear From Our Team</p>
                    <p className="description">See what our team members have to say
                        in our Employee Testimonials section. <br/> Hear about their
                        experiences and opportunities for growth at our company.</p>
                </div>

                <div className="content-card-wrapper">
                    <ScrollCard
                        styleButtonArrowLeft={"absolute top-30 -left-5"}
                        styleButtonArrowRight={"absolute top-30 -right-7"}
                    >
                        {testimonials.map((testi) => (
                            <div style={{backgroundColor: testi?.backgroundColor}}
                                 className="person">
                                <p className="text-quote"><q>{testi?.quote}</q></p>
                                <div className="profile-wrapper">
                                    <LazyImage src={testi?.image} alt={"person1"}
                                               className={"images"}/>
                                    <div className="flex justify-center flex-col gap-2">
                                        <p className="name">{testi?.name}</p>
                                        <p className="role">{testi?.role}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ScrollCard>
                </div>

                <div className="absolute bottom-0 right-0">
                    <LazyImage src={circle} alt={"circle"}/>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CareerPage;
