import React, {SVGProps, useState} from 'react';
import "./RegularButton.scss";
import {RegBtnProps} from "../../../plugins/interfaces/regularButtonInterface";

function RegularButton({
                           label = 'Regular', // label = string
                           borderColor = '', // borderColor = import colors from variables.module.scss
                           backgroundColor = '', // backgroundColor = import colors from variables.module.scss
                           hoverColor = '', // hoverColor = import colors from variables.module.scss
                           fontSize = '', // fontSize = string (with VW | viewport width)
                           fontColor = '', // fontColor = import colors from variables.module.scss
                           fontWeight = 'normal', // fontWeight = string | number (ex "bold", 300, 400, 500)
                           heightButton= '',
                           widthButton = 'fit-content', // widthButton = string (with VW | viewport width)
                           disabled = false,
                           className = '',
                           borderHoverColor='',
                           borderRadius = '0px',
                           iconColor = '',
                           dataTestId = '',
                           onClick,
                       }: RegBtnProps) {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: isHovered ? hoverColor : (disabled ? 'gray' : backgroundColor),
        borderColor: isHovered ? borderHoverColor : (disabled ? 'gray' : borderColor),
        width: widthButton,
        height: heightButton,
        cursor: disabled ? 'not-allowed' : 'pointer',
        borderRadius: borderRadius,
    };

    function IcBaselineEast(props: SVGProps<SVGSVGElement>) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 0 24 24" {...props}><path fill={iconColor} d="m15 5l-1.41 1.41L18.17 11H2v2h16.17l-4.59 4.59L15 19l7-7z"></path></svg>
        )
    }

    return (
        <button
            data-cy={ label }
            onClick={onClick}
            style={buttonStyle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className={"regular-btn " + className}
            disabled={disabled}
            data-testid={ dataTestId }
        >
            <div className="flex justify-center items-center space-x-2">
                <p style={{fontSize, color: fontColor, fontWeight}}>{label}</p>
                <IcBaselineEast style={{color: iconColor}}/>
            </div>
        </button>
    );
}

export default RegularButton;

