import React from 'react';
import {Effect, LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

interface LazyImageProps {
    src: string;
    alt: string;
    height?: string;
    width?: string;
    effect?:  Effect | undefined;
    className?: string;
    placeholderSrc?: string;
}

const LazyImage: React.FC<LazyImageProps> = (
    { src,
        alt,
        height,
        width,
        effect = 'blur',
        className,
        placeholderSrc
    }) => {
    return (
        <LazyLoadImage
            src={src}
            alt={alt}
            height={height}
            width={width}
            effect={effect}
            className={className}
            placeholderSrc={placeholderSrc}
        />
    );
};

export default LazyImage;
