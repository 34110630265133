import React, {useState} from 'react';
import "./IntegratedHealthcare.scss";
import {useNavigate} from "react-router-dom";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import {bgLinearLeftBottom, bgLinearRightTop, burgerBlack, knowUsMobile, logoPeentar} from "../../../assets/svgs";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import Navbar from "../../../components/atoms/Navbar";
import LazyImage from "../../../components/atoms/LazyImage";
import healthcare from "../../../assets/svgs/products/integrated-healthcare/healthcare.png";
import bgEcosystem from "../../../assets/svgs/products/integrated-healthcare/background_ecosystem.png";
import cloud from "../../../assets/svgs/products/integrated-healthcare/cloud.png";
import hospital from "../../../assets/svgs/products/integrated-healthcare/hospital.png";
import bgHealthcare from "../../../assets/svgs/products/integrated-healthcare/background_healthcare.png";
import laptopMedical from "../../../assets/svgs/products/integrated-healthcare/laptopmedical.png";
import technology from "../../../assets/svgs/products/integrated-healthcare/technology.png";
import arTechnology from "../../../assets/svgs/products/integrated-healthcare/ar_technology.png";
import subtract from "../../../assets/svgs/products/integrated-healthcare/subtract.png";
import Footer from "../../../components/atoms/Footer";
import {talkWithUs} from "../../../assets/svgs/about";
import RegularButton from "../../../components/atoms/RegularButton";
import {useScroll} from "../../../components/atoms/ScrollProvider";

function IntegratedHealthcare() {
    const navigate = useNavigate();
    const targetRef = useScroll();
    const [changeNavbar, setChangeNavbar] = useState(false);

    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }

    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }
    return (
        <div onScroll={scrollEvent} className="healthcare-page-wrapper">
            <div className="healthcare-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />


                <div className="healthcare-content-header-wrapper">
                    <NavbarProduct
                        handleNavigateMenuProduct={handleNavigateMenuProduct}
                    />
                    <div className="healthcare-header-content-wrapper">
                        <div className="healthcare-header-content">
                            <div className="content-row-1">
                                <div className="health-care-image-mobile">
                                    <LazyImage src={bgHealthcare} alt={"bg-healthcare"} />
                                    <div className="healthcare-title-wrapper-mobile">
                                        <LazyImage src={healthcare} alt={"healthcare"} className="img-healthcare"/>
                                        <p className="healthcare-title">Integrated Healthcare</p>
                                    </div>
                                </div>
                                <div className="healthcare-title-wrapper">
                                    <LazyImage src={healthcare} alt={"healthcare"}/>
                                    <p className="healthcare-title">Integrated Healthcare</p>
                                </div>
                                <p className="text1">An integrated health care system is a holistic
                                    approach to providing health services that covers various
                                    aspects of care from prevention to advanced care.</p>
                                <p className="text2">In this concept, various health service providers, such as
                                    hospitals, clinics, <br/> laboratories, general practitioners and other health
                                    facilities, work in synergy to <br/> provide coordinated and integrated
                                    care. The primary goal of this system is to <br/> improve quality
                                    of care, efficiency, and accessibility for patients, while reducing <br/>
                                    costs and improving overall health outcomes.</p>
                            </div>
                            <div className="health-care-image">
                                <div className="relative">
                                    <div className="absolute top-0">
                                        <LazyImage src={bgHealthcare} alt={"bg-healthcare"}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="healthcare-container-1-wrapper">
                <div className="bg-linear-right-top-mobile">
                    <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
                </div>
                <p>An integrated healthcare system, patient health information can be shared
                    easily between various health service providers through the use of information
                    technology and electronic medical records. This allows for better coordination
                    of care, prevents overlap in services, and provides more personalized and
                    effective care. A health care team consisting of various professionals,
                    such as doctors, nurses, nutritionists, and other specialists, works together
                    to create a comprehensive treatment plan according to each patient's
                    unique needs.</p>

                <div className="bg-linear-left-bottom-mobile">
                    <LazyImage src={bgLinearLeftBottom} alt={"linear-bg"}/>
                </div>
            </div>
            <div className="healthcare-container-2-wrapper">
                <div className="content-1">
                    <p className="text1">Integrated is core of platform healthcare ecosystem </p>
                    <p className="text2">Integrated healthcare system also emphasizes disease prevention
                        and overall population health. Through prevention and public education programs,
                        this system seeks to reduce the number of disease cases, improve public health,
                        and optimize the use of health resources. With integration, patients
                        can receive more holistic and coordinated care, while healthcare providers
                        can work together to achieve better overall health outcomes.</p>
                </div>
                <div className="content-2">
                    <div className="item-wrapper">
                        <LazyImage src={bgEcosystem} alt={"bg-ecosystem"} width={"100%"}/>
                        <div className="cloud-img">
                            <LazyImage src={cloud} alt={"cloud"} className="cloud"/>
                        </div>
                        <div className="hospital-img">
                            <LazyImage src={hospital} alt={"hospital"} className="hospital" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="healthcare-container-3-wrapper">
                <div className="basis-1/3">
                    <LazyImage src={laptopMedical} alt={"laptop-medical"} width={"100%"}/>
                </div>
                <div className="basis-2/3">
                    <p className="text1">By utilizing Internet of Things (IoT) technology and artificial
                        intelligence (AI) in the Integrated Healthcare System, it can increase efficiency,
                        accuracy and responsiveness in providing health services. The process of collecting
                        real-time medical data from various connected devices and sensors for example,
                        laboratory and radiology equipment, wearable devices to monitor their health parameters
                        such as heart rate, blood pressure and physical activity levels.
                        This data is then integrated into the healthcare system to provide more accurate
                        and faster monitoring of the patient's condition. Data generated from various
                        health equipment and devices will form big data from the health system.
                        AI algorithms can help process the Big Data formed to analyze patterns,
                        trends and provide predictions regarding patient health conditions. With this system,
                        health care providers can receive early notification of potential health
                        problems, so they can respond more quickly and provide more proactive care.</p>
                </div>
            </div>

            <div className="healthcare-container-4-wrapper">
                <LazyImage src={technology} alt={"technology"} width={"100%"}/>
            </div>

            <div className="healthcare-container-4-mobile-wrapper">
                <LazyImage src={arTechnology} alt={"ar_technology"} width={"100%"} className="ar-technology-img"/>
                <div className="subtract-img-wrapper">
                    <LazyImage src={subtract} alt={"subtract"} width={"100%"} className="subtract-img"/>

                    <div className="text-wrapper">
                        <p className="text">Utilizing this technology can also improve care coordination.
                            For example, AI systems can assist in automated scheduling of
                            inter-professional consultations or provide treatment
                            recommendations based on patient data. Additionally, with
                            an integrated communications platform, healthcare teams can
                            communicate more effectively, share information, and develop
                            care plans together. In terms of disease prevention, the system
                            can analyze epidemiological patterns and provide recommendations
                            for more targeted and effective prevention campaigns. Through the
                            integration of these technologies, integrated healthcare systems
                            can provide more personalized, efficient, and proactive care for
                            patients, and improve the overall health of society.</p>
                    </div>
                </div>
            </div>

            <div className="healthcare-container-5-wrapper">
                <LazyImage src={talkWithUs} alt={"talk with us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="healthcare-container-5-mobile-wrapper">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default IntegratedHealthcare;
