import React, {useState} from 'react';
import "./CustomerLoyalty.scss";
import {useNavigate} from "react-router-dom";
import {
    bgLinearLeftBottom,
    bgLinearRightBottom,
    bgLinearRightTop,
    burgerBlack, knowUsMobile,
    logoPeentar
} from "../../../assets/svgs";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import Navbar from "../../../components/atoms/Navbar";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import LazyImage from "../../../components/atoms/LazyImage";
import customerLoyalty from "../../../assets/svgs/products/customer-loyalty/customer_loyalty.png";
import bgCustomerLoyalty from "../../../assets/svgs/products/customer-loyalty/background_customer_loyalty.png";
import points from "../../../assets/svgs/products/customer-loyalty/points.png";
import membership from "../../../assets/svgs/products/customer-loyalty/membership.png";
import programs from "../../../assets/svgs/products/customer-loyalty/tierred_programs.png";
import referral from "../../../assets/svgs/products/customer-loyalty/referral_program.png";
import specialOffers from "../../../assets/svgs/products/customer-loyalty/special_offers.png";
import backgroundBrand from "../../../assets/svgs/products/customer-loyalty/background_brand.png";
import brands from "../../../assets/svgs/products/customer-loyalty/brands.png";
import onlineShopping from "../../../assets/svgs/products/customer-loyalty/online_shopping.png";
import Card from "../../../components/atoms/Card/Card";
import Footer from "../../../components/atoms/Footer";
import {talkWithUs} from "../../../assets/svgs/about";
import RegularButton from "../../../components/atoms/RegularButton";
import {useScroll} from "../../../components/atoms/ScrollProvider";

function CustomerLoyalty() {
    const navigate = useNavigate();
    const targetRef = useScroll();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }
    return (
        <div onScroll={scrollEvent} className="customerLoyalty-page-wrapper">
            <div className="customerLoyalty-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="customerLoyalty-header-content-wrapper">
                    <NavbarProduct
                        handleNavigateMenuProduct={handleNavigateMenuProduct}/>
                    <div className="customerLoyalty-header-content">
                        <div className="content-1">
                            <div className="digital-signature-image-mobile">
                                <LazyImage src={bgCustomerLoyalty} alt={"bg-digital-signature"}/>
                                <div className="title-wrapper-mobile">
                                    <LazyImage src={customerLoyalty} alt={"customer-loyalty"}
                                               className="img-digital-signature"/>
                                    <p className="title">Customer Loyalty</p>
                                </div>
                            </div>
                            <div className="title-wrapper">
                                <LazyImage src={customerLoyalty} alt={"customer-loyalty"}/>
                                <p className="title">Customer Loyalty</p>
                            </div>
                            <p className="text1">Utilized to manage the relationship
                                between businesses and customers</p>
                            <p className="text2">The Customer Loyalty System is a system to support the
                                strategies used by companies to retain existing customers and
                                increase their loyalty to the company's brand or product.
                                The main goal of the Customer Loyalty System is to build a strong
                                relationship between the company and its customers, so that customers
                                will be more likely to choose to continue transacting with
                                the company rather than looking for other product alternatives.
                            </p>
                        </div>
                        <div className="digital-signature-image">
                            <div className="relative">
                                <div className="absolute top-0">
                                    <LazyImage src={bgCustomerLoyalty} alt={"bg-digital-signature"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="customerLoyalty-container-1-wrapper">
                <p className="title">The customer loyalty system provides program setup
                    features or incentives offered to loyal customers as a form
                    of appreciation for their loyalty, including:</p>
                <div className="bg-linear-right-top">
                    <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
                </div>
                <div className="customer-loyalty-card-wrapper">
                    <Card title={"Points and Rewards"}
                          description={"Customers can earn points every time they make a purchase " +
                              "or interact with a company. These points can " +
                              "be collected and exchanged for prizes, discounts or other benefits."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"28em"}
                          widthCard={"8em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.1vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.9vw"}>
                        <LazyImage src={points} alt={"card-img"}/>
                    </Card>

                    <Card title={"Membership"}
                          description={"Customers can register as a loyalty program member " +
                              "and receive additional benefits such as exclusive discounts, " +
                              "priority service or special gifts."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"28em"}
                          widthCard={"8em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.1vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.9vw"}>
                        <LazyImage src={membership} alt={"card-img"}/>
                    </Card>

                    <Card title={"Tiered Programs"}
                          description={"Tiered Programs: Companies can set up tiered " +
                              "programs that provide additional benefits as customer " +
                              "loyalty levels increase. For example, customers who reach " +
                              "a certain level in a tier program might get deeper discounts " +
                              "or access to special events."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"28em"}
                          widthCard={"8em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.1vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.9vw"}>
                        <LazyImage src={programs} alt={"card-img"}/>
                    </Card>

                    <Card title={"Referral Program"}
                          description={"Customers who recommend a company's products or " +
                              "services to others may be given incentives such as additional " +
                              "discounts or free gifts if the referral results in a purchase."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"28em"}
                          widthCard={"8em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.1vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.9vw"}>
                        <LazyImage src={referral} alt={"card-img"}/>
                    </Card>

                    <Card title={"Communications and Special Offers"}
                          description={"Through the Customer Loyalty System, companies can " +
                              "send special communications and offers to loyal customers. " +
                              "This could be information about exclusive sales, new products, " +
                              "or invitations to special events."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"28em"}
                          widthCard={"8em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.1vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"0.9vw"}>
                        <LazyImage src={specialOffers} alt={"card-img"}/>
                    </Card>
                </div>

                <div className="customer-loyalty-card-wrapper-mobile">
                    <Card title={"Points and Rewards"}
                          description={"Customers can earn points every time they make a purchase " +
                              "or interact with a company. These points can " +
                              "be collected and exchanged for prizes, discounts or other benefits."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"auto"}
                          widthCard={"auto"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"5vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"4vw"}>
                        <LazyImage src={points} alt={"card-img"}/>
                    </Card>

                    <Card title={"Membership"}
                          description={"Customers can register as a loyalty program member " +
                              "and receive additional benefits such as exclusive discounts, " +
                              "priority service or special gifts."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"auto"}
                          widthCard={"auto"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"5vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"4vw"}>
                        <LazyImage src={membership} alt={"card-img"}/>
                    </Card>

                    <Card title={"Tiered Programs"}
                          description={"Tiered Programs: Companies can set up tiered " +
                              "programs that provide additional benefits as customer " +
                              "loyalty levels increase. For example, customers who reach " +
                              "a certain level in a tier program might get deeper discounts " +
                              "or access to special events."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"auto"}
                          widthCard={"auto"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"5vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"4vw"}>
                        <LazyImage src={programs} alt={"card-img"}/>
                    </Card>

                    <Card title={"Referral Program"}
                          description={"Customers who recommend a company's products or " +
                              "services to others may be given incentives such as additional " +
                              "discounts or free gifts if the referral results in a purchase."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"auto"}
                          widthCard={"auto"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"5vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"4vw"}>
                        <LazyImage src={referral} alt={"card-img"}/>
                    </Card>

                    <Card title={"Communications and Special Offers"}
                          description={"Through the Customer Loyalty System, companies can " +
                              "send special communications and offers to loyal customers. " +
                              "This could be information about exclusive sales, new products, " +
                              "or invitations to special events."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"start"}
                          heightCard={"auto"}
                          widthCard={"auto"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"5vw"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"4vw"}>
                        <LazyImage src={specialOffers} alt={"card-img"}/>
                    </Card>
                </div>

                <div className="bg-linear-right-bottom">
                    <LazyImage src={bgLinearRightBottom} alt={"linear-bg"}/>
                </div>

                <div className="bg-linear-left-bottom">
                    <LazyImage src={bgLinearLeftBottom} alt={"linear-bg"}/>
                </div>
            </div>

            <div className="customerLoyalty-container-2-wrapper">
                <div className="basis-1/3 relative">
                    <LazyImage src={backgroundBrand} alt={"background-brand"}/>
                    <div className="absolute -top-8 left-2">
                        <LazyImage src={brands} alt={"brand"}/>
                    </div>
                </div>
                <div className="basis-2/3">
                    <p className="text1">Through the Customer Loyalty System</p>
                    <p className="text2">The company hopes to create a closer bond between
                        customers and their brands. By paying attention to and meeting customer needs,
                        as well as providing additional incentives and benefits, companies hope
                        that customers will feel appreciated and will continue to choose
                        to transact with them.</p>
                </div>
            </div>

            <div className="customerLoyalty-container-3-wrapper">
                <div className="basis-2/3">
                    <p className="text1">Customer Loyalty</p>
                    <p className="text2">The Customer Loyalty system provides features that support Customer
                        Experience management using the Mobile App as One Point Access which also acts
                        as a member card that is shown when making transactions at the outlet.
                        The outlet officer will scan the QR Code provided in the Mobile App, the
                        transaction point rewards will be directly entered into the member's data
                        automatically. Likewise, when customers use reward points to pay for shopping
                        at outlets. Customers only need to show the QR Code on the Mobile App, reward
                        points will automatically be used to pay for customer transactions.
                        The Mobile App provides features for doing online shopping from a smartphone
                        that is integrated with outlets via an Omni-Channel System. The Mobile App can
                        be downloaded via Google Play for the Android version and Apple App Store
                        for the iOS version.</p>
                </div>
                <div className="basis-1/3">
                    <LazyImage src={onlineShopping} alt={"online-shopping"}/>
                </div>
            </div>

            <div className="customerLoyalty-container-4-wrapper">
                <LazyImage src={talkWithUs} alt={"talk with us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="customerLoyalty-container-4-mobile-wrapper">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default CustomerLoyalty;
