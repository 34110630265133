import React, {useState} from 'react';
import Navbar from "../../../components/atoms/Navbar";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import {useNavigate} from "react-router-dom";
import "./Pos.scss";
import Card from "../../../components/atoms/Card/Card";
import RegularButton from "../../../components/atoms/RegularButton";
import Footer from "../../../components/atoms/Footer";
import ScrollCard from "../../../components/atoms/ScrollCard";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import LazyImage from "../../../components/atoms/LazyImage";
import {burgerBlack, knowUsMobile, logoPeentar} from "../../../assets/svgs";
import {
    backgroundPos,
    customer,
    customerExperience,
    deliveryOrder,
    ecommerceIntegration,
    increasedTransaction,
    inventoryManagement,
    payment,
    pointOfSales,
    salesAnalysis,
    salesMonitoring,
    stockManagement,
    testimonial1,
    testimonial2,
    transaction,
    transactionOutlet,
} from "../../../assets/svgs/products/pos";
import testimonial1Mobile from "../../../assets/svgs/products/pos/testimonial1_mobile.png";
import testimonial2Mobile from "../../../assets/svgs/products/pos/testimonial2_mobile.png";
import {talkWithUs} from "../../../assets/svgs/about";
import {useScroll} from "../../../components/atoms/ScrollProvider";

function Pos() {
    const navigate = useNavigate();
    const targetRef = useScroll();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const [isHoveredCustomer, setIsHoveredCustomer] = useState(false);
    const [isHoveredTransaction, setIsHoveredTransaction] = useState(false);
    const [isHoveredPayment, setIsHoveredPayment] = useState(false);
    const [isHoveredOrder, setIsHoveredOrder] = useState(false);
    const [isHoveredEcommerce, setIsHoveredEcommerce] = useState(false);
    const [isHoveredSales, setIsHoveredSales] = useState(false);
    const [isHoveredStock, setIsHoveredStock] = useState(false);
    const [isHoveredOutlets, setIsHoveredOutlets] = useState(false);
    const [isHoveredIncreasedTransaction, setIsHoveredIncreasedTransaction] = useState(false);
    const [isHoveredInventoryManagement, seIsHoveredInventoryManagement] = useState(false);
    const [isHoveredCustomerExperience, setIsHoveredCustomerExperience] = useState(false);
    const [isHoveredSalesAnalysis, setIsHoveredSalesAnalysis] = useState(false);
    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }

    return (
        <div onScroll={scrollEvent} className="pos-page-wrapper">
            <div className="pos-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="pos-header-content-wrapper">
                    <NavbarProduct
                        handleNavigateMenuProduct={handleNavigateMenuProduct}
                    />
                    <div className="pos-header-content">
                        <div className="pos-header">
                            <div className="pos-title-wraper">
                                <div className="pos-row-1">
                                    <LazyImage src={pointOfSales} alt={"point-of-sales"} className={"img-pos"}/>
                                    <p className="pos-title">Point of Sales</p>
                                </div>
                                <div className="image-pos-mobile basis-1/3 flex justify-center">
                                    <LazyImage src={backgroundPos}
                                               alt={"background-pos"}/>
                                </div>
                                <div className="pos-row-2">
                                    <p className="text1">Dynamic <br/> Cloud-Integrated <br/> POS Solution</p>
                                    <p className="text2">The POS system provides features to facilitate
                                        transactions
                                        at <br/>outlets. POS architecture is prepared with a combination
                                        of <br/>
                                        Cloud Architecture and Distributed Architecture. In the event <br/>
                                        that the internet network connection is lost, the POS can still be <br/>
                                        used to carry out transactions. When the network connection is <br/>
                                        reconnected, the synchronization process will immediately run <br/>
                                        again automatically.
                                    </p>
                                    <p className="text2-mobile">The POS system provides features to facilitate
                                        transactions
                                        at outlets. POS architecture is prepared with a combination
                                        of
                                        Cloud Architecture and Distributed Architecture. In the event
                                        that the internet network connection is lost, the POS can still be
                                        used to carry out transactions. When the network connection is
                                        reconnected, the synchronization process will immediately run
                                        again automatically.
                                    </p>
                                </div>
                                <div className="pos-row-3">
                                    <div className="text-wrapper">
                                        <p className="text1">1800 +</p>
                                        <p className="text2">Integrated Store</p>
                                    </div>
                                    <div className="text-wrapper">
                                        <p className="text1">30 +</p>
                                        <p className="text2">Connected City</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image-pos basis-1/3 flex justify-center">
                            <LazyImage src={backgroundPos}
                                       alt={"background-pos"}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pos-content-1-wrapper">
                <div className="pos-content-1">
                    <div className="pos-content-title">
                        <p className="text1">pos sistem ability</p>
                        <p className="text2">Empower Your Business with our <br/> POS System Features</p>
                    </div>
                    <div className="pos-content-card">
                        {isHoveredCustomer ?
                            <Card title={"CUSTOMER"}
                                  description={"The POS system records transactions and signs up " +
                                      "new members during purchases. After buying, customers earn " +
                                      "reward points for future payments. These points can be " +
                                      "used in-store or online by showing a member card or " +
                                      "scanning a QR Code. Customers receive email notifications " +
                                      "thanking them for their purchase and showing their " +
                                      "earned points, along with a link to download " +
                                      "the Member Mobile App."}
                                  backgroundColor={"#FFFFFF33"}
                                  alignItemsCard={"start"}
                                  heightCard={"31em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1.1em"}
                                  handleMouseEnter={() => setIsHoveredCustomer(true)}
                                  handleMouseLeave={() => setIsHoveredCustomer(false)}/> :
                            <Card title={"CUSTOMER"}
                                  description={"POS provides a feature to record customers who make " +
                                      "transactions. If you..."}
                                  backgroundColor={"#FFFFFF33"}
                                  alignItemsCard={"start"}
                                  heightCard={"31em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1.1em"}
                                  handleMouseEnter={() => setIsHoveredCustomer(true)}
                                  handleMouseLeave={() => setIsHoveredCustomer(false)}>
                                <LazyImage src={customer} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredTransaction ? <Card
                            title={"ONHOLD TRANSACTION"}
                            description={"POS provides a feature for customers to reserve one or " +
                                "more items during a transaction, and will collect them later. " +
                                "How long this reserve is valid can be set in the system. This " +
                                "reserve time is determined centrally and then synchronized " +
                                "to the POS at all outlets.\n"}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredTransaction(true)}
                            handleMouseLeave={() => setIsHoveredTransaction(false)}>
                        </Card> : <Card
                            title={"ONHOLD TRANSACTION"}
                            description={"POS provides a feature for customers to reserve one or " +
                                "more items during a transac..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredTransaction(true)}
                            handleMouseLeave={() => setIsHoveredTransaction(false)}>
                            <LazyImage src={transaction} alt={"card-img"}/>
                        </Card>}

                        {isHoveredPayment ? <Card
                            title={"PAYMENT METHOD"}
                            description={"POS provides features for accepting payments in cash, " +
                                "credit, COD, vouchers, reward points and BPJS. " +
                                "In one transaction, customers are allowed to make payments " +
                                "using a combination of more than one type of payment method.\n"}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredPayment(true)}
                            handleMouseLeave={() => setIsHoveredPayment(false)}
                        /> : <Card
                            title={"PAYMENT METHOD"}
                            description={"POS provides features for accepting payments in cash, " +
                                "credit, COD, vouchers..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredPayment(true)}
                            handleMouseLeave={() => setIsHoveredPayment(false)}
                        >
                            <img src={payment} alt="card-img"/>
                        </Card>}

                        {isHoveredOrder ? <Card
                            title={"DELIVERY ORDER"}
                            description={"The POS system lets you choose how you get your items: " +
                                "pick up directly, from another outlet, or delivered to your address. " +
                                "If you pick up from another outlet, it records when you'll get " +
                                "it and shows if items are available. For deliveries, it estimates " +
                                "when your item will arrive. The system also tracks deliveries, " +
                                "whether done by the outlet or another one upon request."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredOrder(true)}
                            handleMouseLeave={() => setIsHoveredOrder(false)}
                        /> : <Card
                            title={"DELIVERY ORDER"}
                            description={"POS provides a feature for recording delivery methods...."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredOrder(true)}
                            handleMouseLeave={() => setIsHoveredOrder(false)}
                        >
                            <LazyImage src={deliveryOrder} alt={"card-img"}/>
                        </Card>}

                        {isHoveredEcommerce ? <Card
                            title={"ECOMMERCE INTEGRATION"}
                            description={"The POS system accepts payments for shopping carts from " +
                                "the Online Store or Mobile App. Customers can access their cart " +
                                "by showing a QR Code on the Mobile App, which the staff scans " +
                                "at the POS. If customers shop online, they can choose to pick " +
                                "up items at specific outlets. The Online Store shows available " +
                                "stock at nearby outlets based on the customer's location."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredEcommerce(true)}
                            handleMouseLeave={() => setIsHoveredEcommerce(false)}
                        /> : <Card
                            title={"ECOMMERCE INTEGRATION"}
                            description={"POS provides a feature to accept payments for shopping carts " +
                                "created by..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredEcommerce(true)}
                            handleMouseLeave={() => setIsHoveredEcommerce(false)}
                        >
                            <LazyImage src={ecommerceIntegration} alt={"card-img"}/>
                        </Card>}

                        {isHoveredSales ? <Card
                            title={"SALES MONITORING"}
                            description={"The system provides features to monitor the achievement " +
                                "of sales targets in total and per item of goods at outlets " +
                                "for daily, monthly, quarterly, semester and one year periods. " +
                                "Achievements can also be compared with achievements in the same " +
                                "period the previous year. The system provides a feature to " +
                                "drill down sales data based on customer. The system displays " +
                                "monitoring data in tabular and graphical form."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredSales(true)}
                            handleMouseLeave={() => setIsHoveredSales(false)}
                        /> : <Card
                            title={"SALES MONITORING"}
                            description={"The system provides features to monitor the achievement of sales targets in total..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"31em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredSales(true)}
                            handleMouseLeave={() => setIsHoveredSales(false)}
                        >
                            <LazyImage src={salesMonitoring} alt={"card-img"}/>
                        </Card>}


                        {isHoveredStock ? <Card
                            title={"STOCK MANAGEMENT"}
                            description={"The system provides features to monitor the status of goods " +
                                "request activities, goods receipt, goods release, transfer between " +
                                "outlets and expiry date. For activity status that does not reach " +
                                "the specified time target, the system will send warnings and " +
                                "notifications to the users and/or user groups that have been " +
                                "determined."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"30em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredStock(true)}
                            handleMouseLeave={() => setIsHoveredStock(false)}
                        /> : <Card
                            title={"STOCK MANAGEMENT"}
                            description={"The system provides features to monitor the status of goods request activities, g..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"30em"}
                            widthCard={"22em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredStock(true)}
                            handleMouseLeave={() => setIsHoveredStock(false)}
                        >
                            <LazyImage src={stockManagement} alt={"card-img"}/>
                        </Card>}
                        {isHoveredOutlets ? <Card
                            title={"TRANSACTIONS BETWEEN OUTLETS"}
                            description={"The system provides features to monitor the achievement " +
                                "of sales targets in total and per item of goods at outlets for " +
                                "daily, monthly, quarterly, semester and one year periods. Achievements " +
                                "can also be compared with achievements in the same period the " +
                                "previous year. The system provides a feature to drill down sales " +
                                "data based on customer. The system displays monitoring data in " +
                                "tabular and graphical form."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"30em"}
                            widthCard={"49em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredOutlets(true)}
                            handleMouseLeave={() => setIsHoveredOutlets(false)}
                        /> : <Card
                            title={"TRANSACTIONS BETWEEN OUTLETS"}
                            description={"The system provides features to monitor the " +
                                "achievement of sales targets in total and per item " +
                                "of goods at outlets for daily, monthly, quarterly, " +
                                "semester and one year periods. Achievements..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"30em"}
                            widthCard={"49em"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            handleMouseEnter={() => setIsHoveredOutlets(true)}
                            handleMouseLeave={() => setIsHoveredOutlets(false)}
                        >
                            <LazyImage src={transactionOutlet} alt={"card-img"}/>
                        </Card>}
                    </div>
                </div>
            </div>

            <div className="pos-content-1-wrapper-mobile">
                <div className="pos-content-1">
                    <div className="pos-content-title">
                        <p className="text1">pos sistem ability</p>
                        <p className="text2">Empower Your Business with our <br/> POS System Features</p>
                    </div>
                    <div className="pos-content-card">
                        {isHoveredCustomer ?
                            <Card title={"CUSTOMER"}
                                  description={"The POS system records transactions and signs up " +
                                      "new members during purchases. After buying, customers earn " +
                                      "reward points for future payments. These points can be " +
                                      "used in-store or online by showing a member card or " +
                                      "scanning a QR Code. Customers receive email notifications " +
                                      "thanking them for their purchase and showing their " +
                                      "earned points, along with a link to download " +
                                      "the Member Mobile App."}
                                  backgroundColor={"#FFFFFF33"}
                                  alignItemsCard={"start"}
                                  heightCard={"auto"}
                                  widthCard={"auto"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredCustomer(!isHoveredCustomer)}
                                  handleMouseEnter={() => setIsHoveredCustomer(true)}
                                  handleMouseLeave={() => setIsHoveredCustomer(false)}/> :
                            <Card title={"CUSTOMER"}
                                  description={"POS provides a feature to record customers who make " +
                                      "transactions. If you..."}
                                  backgroundColor={"#FFFFFF33"}
                                  alignItemsCard={"start"}
                                  heightCard={"auto"}
                                  widthCard={"auto"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredCustomer(!isHoveredCustomer)}
                                  handleMouseEnter={() => setIsHoveredCustomer(true)}
                                  handleMouseLeave={() => setIsHoveredCustomer(false)}>
                                <LazyImage src={customer} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredTransaction ? <Card
                            title={"ONHOLD TRANSACTION"}
                            description={"POS provides a feature for customers to reserve one or " +
                                "more items during a transaction, and will collect them later. " +
                                "How long this reserve is valid can be set in the system. This " +
                                "reserve time is determined centrally and then synchronized " +
                                "to the POS at all outlets.\n"}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredTransaction(!isHoveredTransaction)}
                            handleMouseEnter={() => setIsHoveredTransaction(true)}
                            handleMouseLeave={() => setIsHoveredTransaction(false)}>
                        </Card> : <Card
                            title={"ONHOLD TRANSACTION"}
                            description={"POS provides a feature for customers to reserve one or " +
                                "more items during a transac..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredTransaction(!isHoveredTransaction)}
                            handleMouseEnter={() => setIsHoveredTransaction(true)}
                            handleMouseLeave={() => setIsHoveredTransaction(false)}>
                            <LazyImage src={transaction} alt={"card-img"}/>
                        </Card>}

                        {isHoveredPayment ? <Card
                            title={"PAYMENT METHOD"}
                            description={"POS provides features for accepting payments in cash, " +
                                "credit, COD, vouchers, reward points and BPJS. " +
                                "In one transaction, customers are allowed to make payments " +
                                "using a combination of more than one type of payment method.\n"}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredPayment(!isHoveredPayment)}
                            handleMouseEnter={() => setIsHoveredPayment(true)}
                            handleMouseLeave={() => setIsHoveredPayment(false)}
                        /> : <Card
                            title={"PAYMENT METHOD"}
                            description={"POS provides features for accepting payments in cash, " +
                                "credit, COD, vouchers..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredPayment(!isHoveredPayment)}
                            handleMouseEnter={() => setIsHoveredPayment(true)}
                            handleMouseLeave={() => setIsHoveredPayment(false)}
                        >
                            <img src={payment} alt="card-img"/>
                        </Card>}

                        {isHoveredOrder ? <Card
                            title={"DELIVERY ORDER"}
                            description={"The POS system lets you choose how you get your items: " +
                                "pick up directly, from another outlet, or delivered to your address. " +
                                "If you pick up from another outlet, it records when you'll get " +
                                "it and shows if items are available. For deliveries, it estimates " +
                                "when your item will arrive. The system also tracks deliveries, " +
                                "whether done by the outlet or another one upon request."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredOrder(!isHoveredOrder)}
                            handleMouseEnter={() => setIsHoveredOrder(true)}
                            handleMouseLeave={() => setIsHoveredOrder(false)}
                        /> : <Card
                            title={"DELIVERY ORDER"}
                            description={"POS provides a feature for recording delivery methods...."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredOrder(!isHoveredOrder)}
                            handleMouseEnter={() => setIsHoveredOrder(true)}
                            handleMouseLeave={() => setIsHoveredOrder(false)}
                        >
                            <LazyImage src={deliveryOrder} alt={"card-img"}/>
                        </Card>}

                        {isHoveredEcommerce ? <Card
                            title={"ECOMMERCE INTEGRATION"}
                            description={"The POS system accepts payments for shopping carts from " +
                                "the Online Store or Mobile App. Customers can access their cart " +
                                "by showing a QR Code on the Mobile App, which the staff scans " +
                                "at the POS. If customers shop online, they can choose to pick " +
                                "up items at specific outlets. The Online Store shows available " +
                                "stock at nearby outlets based on the customer's location."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredEcommerce(!isHoveredEcommerce)}
                            handleMouseEnter={() => setIsHoveredEcommerce(true)}
                            handleMouseLeave={() => setIsHoveredEcommerce(false)}
                        /> : <Card
                            title={"ECOMMERCE INTEGRATION"}
                            description={"POS provides a feature to accept payments for shopping carts " +
                                "created by..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredEcommerce(!isHoveredEcommerce)}
                            handleMouseEnter={() => setIsHoveredEcommerce(true)}
                            handleMouseLeave={() => setIsHoveredEcommerce(false)}
                        >
                            <LazyImage src={ecommerceIntegration} alt={"card-img"}/>
                        </Card>}

                        {isHoveredSales ? <Card
                            title={"SALES MONITORING"}
                            description={"The system provides features to monitor the achievement " +
                                "of sales targets in total and per item of goods at outlets " +
                                "for daily, monthly, quarterly, semester and one year periods. " +
                                "Achievements can also be compared with achievements in the same " +
                                "period the previous year. The system provides a feature to " +
                                "drill down sales data based on customer. The system displays " +
                                "monitoring data in tabular and graphical form."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredSales(!isHoveredSales)}
                            handleMouseEnter={() => setIsHoveredSales(true)}
                            handleMouseLeave={() => setIsHoveredSales(false)}
                        /> : <Card
                            title={"SALES MONITORING"}
                            description={"The system provides features to monitor the achievement of sales targets in total..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"1.3em"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"1.1em"}
                            onClickCard={() => setIsHoveredSales(!isHoveredSales)}
                            handleMouseEnter={() => setIsHoveredSales(true)}
                            handleMouseLeave={() => setIsHoveredSales(false)}
                        >
                            <LazyImage src={salesMonitoring} alt={"card-img"}/>
                        </Card>}


                        {isHoveredStock ? <Card
                            title={"STOCK MANAGEMENT"}
                            description={"The system provides features to monitor the status of goods " +
                                "request activities, goods receipt, goods release, transfer between " +
                                "outlets and expiry date. For activity status that does not reach " +
                                "the specified time target, the system will send warnings and " +
                                "notifications to the users and/or user groups that have been " +
                                "determined."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredStock(!isHoveredStock)}
                            handleMouseEnter={() => setIsHoveredStock(true)}
                            handleMouseLeave={() => setIsHoveredStock(false)}
                        /> : <Card
                            title={"STOCK MANAGEMENT"}
                            description={"The system provides features to monitor the status of goods request activities, g..."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredStock(!isHoveredStock)}
                            handleMouseEnter={() => setIsHoveredStock(true)}
                            handleMouseLeave={() => setIsHoveredStock(false)}
                        >
                            <LazyImage src={stockManagement} alt={"card-img"}/>
                        </Card>}

                        {isHoveredOutlets ? <Card
                            title={"TRANSACTIONS BETWEEN OUTLETS"}
                            description={"The system provides features to monitor the achievement " +
                                "of sales targets in total and per item of goods at outlets for " +
                                "daily, monthly, quarterly, semester and one year periods. Achievements " +
                                "can also be compared with achievements in the same period the " +
                                "previous year. The system provides a feature to drill down sales " +
                                "data based on customer. The system displays monitoring data in " +
                                "tabular and graphical form."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredOutlets(!isHoveredOutlets)}
                            handleMouseEnter={() => setIsHoveredOutlets(true)}
                            handleMouseLeave={() => setIsHoveredOutlets(false)}
                        /> : <Card
                            title={"TRANSACTIONS BETWEEN OUTLETS"}
                            description={"The system provides features to monitor the achievement.."}
                            backgroundColor={"#FFFFFF33"}
                            alignItemsCard={"start"}
                            heightCard={"auto"}
                            widthCard={"auto"}
                            colorTitle={"#FFFFFF"}
                            fontSizeTitle={"5vw"}
                            fontWeightTitle={500}
                            colorContent={"#FFFFFF"}
                            fontSizeContent={"4vw"}
                            onClickCard={() => setIsHoveredOutlets(!isHoveredOutlets)}
                            handleMouseEnter={() => setIsHoveredOutlets(true)}
                            handleMouseLeave={() => setIsHoveredOutlets(false)}
                        >
                            <LazyImage src={transactionOutlet} alt={"card-img"}/>
                        </Card>}
                    </div>
                </div>
            </div>

            <div className="pos-content-2-wrapper">
                <div className="pos-content-title-wrapper">
                    <p className="text1">case study</p>
                    <p className="text2">How Our Sytem Transformed <br/> Businesses</p>
                </div>

                <div className="pos-content-card-wrapper">
                    <ScrollCard
                        styleButtonArrowLeft={"absolute top-30 -left-5"}
                        styleButtonArrowRight={"absolute top-30 -right-7"}
                    >
                        {isHoveredIncreasedTransaction ?
                            <Card title={"Increased Transaction Efficiency"}
                                  description={"A fast food restaurant adopted our POS system " +
                                      "to manage their daily transactions. By using fast " +
                                      "checkout features and integration with existing " +
                                      "payment systems, the restaurant reported significant " +
                                      "improvements in service speed and reduced queues."}
                                  backgroundColor={"#3895DE"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => setIsHoveredIncreasedTransaction(true)}
                                  handleMouseLeave={() => setIsHoveredIncreasedTransaction(false)}/> :
                            <Card title={"Increased Transaction Efficiency"}
                                  description={"A fast food restaurant adopted our POS system " +
                                      "to manage their daily transactio..."}
                                  backgroundColor={"#3895DE"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => setIsHoveredIncreasedTransaction(true)}
                                  handleMouseLeave={() => setIsHoveredIncreasedTransaction(false)}>
                                <LazyImage src={increasedTransaction} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredInventoryManagement ?
                            <Card title={"Efficient Inventory Management"}
                                  description={"A retail store implemented our POS system to manage their " +
                                      "inventory efficiently. With real-time stock monitoring features " +
                                      "and the ability to create automated orders when stock is low, " +
                                      "the store was able to reduce inventory costs and increase " +
                                      "product availability."}
                                  backgroundColor={"#32A0D5"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => seIsHoveredInventoryManagement(true)}
                                  handleMouseLeave={() => seIsHoveredInventoryManagement(false)}/> :
                            <Card title={"Efficient Inventory Management"}
                                  description={"A retail store implemented our POS system " +
                                      "to manage their inventory efficie..."}
                                  backgroundColor={"#32A0D5"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => seIsHoveredInventoryManagement(true)}
                                  handleMouseLeave={() => seIsHoveredInventoryManagement(false)}>
                                <LazyImage src={inventoryManagement} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredCustomerExperience ?
                            <Card title={"Improving Customer Experience"}
                                  description={"A local cafe uses our POS system to improve the customer " +
                                      "experience. With the table order feature, customers can order " +
                                      "food and drinks easily through the app, increasing the speed " +
                                      "of service and providing a more convenient experience for customers."}
                                  backgroundColor={"#24BAC0"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => setIsHoveredCustomerExperience(true)}
                                  handleMouseLeave={() => setIsHoveredCustomerExperience(false)}/> :
                            <Card title={"Improving Customer Experience"}
                                  description={"A local cafe uses our POS system " +
                                      "to improve the customer experience. With t.."}
                                  backgroundColor={"#24BAC0"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => setIsHoveredCustomerExperience(true)}
                                  handleMouseLeave={() => setIsHoveredCustomerExperience(false)}>
                                <LazyImage src={customerExperience} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredSalesAnalysis ?
                            <Card title={"In-Depth Sales Analysis"}
                                  description={"A fashion clothing store implemented our POS system " +
                                      "to analyze their sales data in more depth. With advanced " +
                                      "reporting features, they can track sales trends, identify " +
                                      "best-selling products, and create more effective " +
                                      "marketing strategies."}
                                  backgroundColor={"#1ACBB2"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => setIsHoveredSalesAnalysis(true)}
                                  handleMouseLeave={() => setIsHoveredSalesAnalysis(false)}/> :
                            <Card title={"In-Depth Sales Analysis"}
                                  description={"A fashion clothing store implemented our POS system " +
                                      "to analyze their sales data i..."}
                                  backgroundColor={"#1ACBB2"}
                                  alignItemsCard={"start"}
                                  heightCard={"28em"}
                                  widthCard={"22em"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"1.3em"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"1em"}
                                  handleMouseEnter={() => setIsHoveredSalesAnalysis(true)}
                                  handleMouseLeave={() => setIsHoveredSalesAnalysis(false)}>
                                <LazyImage src={salesAnalysis} alt={"card-img"}/>
                            </Card>
                        }
                    </ScrollCard>
                </div>

                <div className="pos-content-card-wrapper-mobile">
                    <ScrollCard
                        styleButtonArrowLeft={"absolute top-30 -left-5"}
                        styleButtonArrowRight={"absolute top-30 -right-7"}
                    >
                        {isHoveredIncreasedTransaction ?
                            <Card title={"Increased Transaction Efficiency"}
                                  description={"A fast food restaurant adopted our POS system " +
                                      "to manage their daily transactions. By using fast " +
                                      "checkout features and integration with existing " +
                                      "payment systems, the restaurant reported significant " +
                                      "improvements in service speed and reduced queues."}
                                  backgroundColor={"#3895DE"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredIncreasedTransaction(!isHoveredIncreasedTransaction)}
                                  handleMouseEnter={() => setIsHoveredIncreasedTransaction(true)}
                                  handleMouseLeave={() => setIsHoveredIncreasedTransaction(false)}/> :
                            <Card title={"Increased Transaction Efficiency"}
                                  description={"A fast food restaurant adopted our POS system " +
                                      "to manage their daily transactio..."}
                                  backgroundColor={"#3895DE"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredIncreasedTransaction(!isHoveredIncreasedTransaction)}
                                  handleMouseEnter={() => setIsHoveredIncreasedTransaction(true)}
                                  handleMouseLeave={() => setIsHoveredIncreasedTransaction(false)}>
                                <LazyImage src={increasedTransaction} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredInventoryManagement ?
                            <Card title={"Efficient Inventory Management"}
                                  description={"A retail store implemented our POS system to manage their " +
                                      "inventory efficiently. With real-time stock monitoring features " +
                                      "and the ability to create automated orders when stock is low, " +
                                      "the store was able to reduce inventory costs and increase " +
                                      "product availability."}
                                  backgroundColor={"#32A0D5"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => seIsHoveredInventoryManagement(!isHoveredInventoryManagement)}
                                  handleMouseEnter={() => seIsHoveredInventoryManagement(true)}
                                  handleMouseLeave={() => seIsHoveredInventoryManagement(false)}/> :
                            <Card title={"Efficient Inventory Management"}
                                  description={"A retail store implemented our POS system " +
                                      "to manage their inventory efficie..."}
                                  backgroundColor={"#32A0D5"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => seIsHoveredInventoryManagement(!isHoveredInventoryManagement)}
                                  handleMouseEnter={() => seIsHoveredInventoryManagement(true)}
                                  handleMouseLeave={() => seIsHoveredInventoryManagement(false)}>
                                <LazyImage src={inventoryManagement} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredCustomerExperience ?
                            <Card title={"Improving Customer Experience"}
                                  description={"A local cafe uses our POS system to improve the customer " +
                                      "experience. With the table order feature, customers can order " +
                                      "food and drinks easily through the app, increasing the speed " +
                                      "of service and providing a more convenient experience for customers."}
                                  backgroundColor={"#24BAC0"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredCustomerExperience(!isHoveredCustomerExperience)}
                                  handleMouseEnter={() => setIsHoveredCustomerExperience(true)}
                                  handleMouseLeave={() => setIsHoveredCustomerExperience(false)}/> :
                            <Card title={"Improving Customer Experience"}
                                  description={"A local cafe uses our POS system " +
                                      "to improve the customer experience. With t.."}
                                  backgroundColor={"#24BAC0"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredCustomerExperience(!isHoveredCustomerExperience)}
                                  handleMouseEnter={() => setIsHoveredCustomerExperience(true)}
                                  handleMouseLeave={() => setIsHoveredCustomerExperience(false)}>
                                <LazyImage src={customerExperience} alt={"card-img"}/>
                            </Card>
                        }

                        {isHoveredSalesAnalysis ?
                            <Card title={"In-Depth Sales Analysis"}
                                  description={"A fashion clothing store implemented our POS system " +
                                      "to analyze their sales data in more depth. With advanced " +
                                      "reporting features, they can track sales trends, identify " +
                                      "best-selling products, and create more effective " +
                                      "marketing strategies."}
                                  backgroundColor={"#1ACBB2"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredSalesAnalysis(!isHoveredSalesAnalysis)}
                                  handleMouseEnter={() => setIsHoveredSalesAnalysis(true)}
                                  handleMouseLeave={() => setIsHoveredSalesAnalysis(false)}/> :
                            <Card title={"In-Depth Sales Analysis"}
                                  description={"A fashion clothing store implemented our POS system " +
                                      "to analyze their sales data i..."}
                                  backgroundColor={"#1ACBB2"}
                                  alignItemsCard={"start"}
                                  heightCard={"50vh"}
                                  widthCard={"60vw"}
                                  colorTitle={"#FFFFFF"}
                                  fontSizeTitle={"5vw"}
                                  fontWeightTitle={500}
                                  colorContent={"#FFFFFF"}
                                  fontSizeContent={"4vw"}
                                  onClickCard={() => setIsHoveredSalesAnalysis(!isHoveredSalesAnalysis)}
                                  handleMouseEnter={() => setIsHoveredSalesAnalysis(true)}
                                  handleMouseLeave={() => setIsHoveredSalesAnalysis(false)}>
                                <LazyImage src={salesAnalysis} alt={"card-img"}/>
                            </Card>
                        }
                    </ScrollCard>
                </div>
            </div>

            <div className="pos-content-3-wrapper">
                <div className="pos-content-3-title-wrapper">
                    <p className="text1">Customer Stories</p>
                    <p className="text2">Testimonial</p>
                </div>
                <div className="pos-testimonial-content-3-wrapper">
                    <ScrollCard
                        styleButtonArrowLeft={"absolute top-40 left-0"}
                        styleButtonArrowRight={"absolute top-40 right-0"}
                    >
                        <img className="testimonial1" src={testimonial1} alt="testimonial1"/>
                        <img className="testimonial2" src={testimonial2} alt="testimonial2"/>
                    </ScrollCard>
                </div>
            </div>

            <div className="pos-content-3-mobile-wrapper">
                <div className="pos-content-3-title-mobile-wrapper">
                    <p className="text1">Customer Stories</p>
                    <p className="text2">Testimonial</p>
                </div>

                <div className="pos-testimonial-content-3-mobile-wrapper">
                    <ScrollCard
                        styleButtonArrowLeft={"absolute top-40 left-0"}
                        styleButtonArrowRight={"absolute top-40 right-0"}
                    >
                        <div className="card-testimonial">
                            <LazyImage src={testimonial1Mobile} alt={"testimonial1-mobile"}/>
                            <p className="text1"><q>this system really helps</q></p>
                            <p className="text2">Ready to enhance your business operations with our POS system? Whether
                                you have questions about our features or are eager to begin,
                                our team is here to assist you.</p>
                        </div>

                        <div className="card-testimonial">
                            <LazyImage src={testimonial2Mobile} alt={"testimonial1-mobile"}/>
                            <p className="text1">this system really helps</p>
                            <p className="text2">Ready to enhance your business operations with our POS system? Whether
                                you have questions about our features or are eager to begin,
                                our team is here to assist you.</p>
                        </div>

                    </ScrollCard>
                </div>
            </div>

            <div className="pos-content-4-wrapper">
                <LazyImage src={talkWithUs} alt={"talk with us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="pos-content-4-mobile-wrapper">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Pos;
