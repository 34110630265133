import React, {SVGProps} from 'react';
import "./CardItem.scss";
import {CardItemProps} from "../../../plugins/interfaces/cardItemInterface";
import {iconAbout} from "../../../assets/svgs";
import LazyImage from "../LazyImage";

function CardItem({
                      titleProduct = "",
                      product = '',
                      contentProduct = '',
                      backgroundColor = '',
                      width = '',
                      height = "",
                      navigateToMenuProduct
                  }: CardItemProps) {
    function IcBaselineEast(props: SVGProps<SVGSVGElement>) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
                <path fill={"#ffffff"} d="m15 5l-1.41 1.41L18.17 11H2v2h16.17l-4.59 4.59L15 19l7-7z"></path>
            </svg>
        )
    }

    const cardItemStyle = {
        backgroundColor: backgroundColor
    }

    return (
        <div style={cardItemStyle} className="card-item">
            <LazyImage src={product} alt={"product"} className="productImg -mt-24" width={width} height={height} />
            <div className="description-product">
                <p className="description-title-product">{titleProduct}</p>
                <p className="description-content-product">{contentProduct}</p>
                <button onClick={navigateToMenuProduct} className="flex items-center space-x-3">
                    <span className="text-white text-lg">Know More</span>
                    <IcBaselineEast className="iconBaseline"/>
                </button>
            </div>
        </div>
    );
}

export default CardItem;
