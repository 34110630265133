import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import Navbar from "../../components/atoms/Navbar";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    navbarStyle,
    navbarStyleWhite,
} from "../../plugins/constant/navbar-styles.constant";
import Footer from "../../components/atoms/Footer";
import "./ServicePage.scss";
import {
    advancedImg,
    burgerBlack,
    burgerWhite,
    logoPeentar,
    logoPeentarWhite,
    relevantImg,
    securedImg
} from "../../assets/svgs";
import LazyImage from "../../components/atoms/LazyImage";
import {
    bgImageService, call,
    imageService,
    infrastructure, instagram,
    laptop,
    leftCane, message,
    rightCane, sectionMobileService3, sectionMobileService5, sectionMobileService6,
    talkUs
} from "../../assets/svgs/service";
import {useScroll} from "../../components/atoms/ScrollProvider";

function ServicePage() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const targetRef = useScroll();
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const handleInstagramClick = () => {
        window.open('https://www.instagram.com/peentar.id', '_blank');
    };

    const handlePhoneClick = () => {
        window.location.href = 'tel:+62217265907';
    };

    const handleEmailClick = () => {
        window.location.href = 'mailto:solution@teknologipeentar.com';
    };

    return (
        <div onScroll={scrollEvent}
             className="service-page-wrapper scroll-smooth">
            <div className="service-header-page bg-cover bg-center">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={changeNavbar ? logoPeentar : logoPeentarWhite}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    iconNavbar={changeNavbar ? burgerBlack : burgerWhite}
                    fontMenuProductStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="service-header-content-mobile-wrapper">
                    <p className="title-header">We ensure that the technology is <br/>
                        relevant to business <br/> needs</p>
                </div>
            </div>

            <div className="service-content-1-wrapper">
                <div className="section-1">
                    <div className="image-wrapper">
                        <LazyImage src={bgImageService} alt={"bg-image-service"} className={"bg-image-service"}/>
                        <div className="img-service-wrapper">
                            <LazyImage src={imageService} alt={"image-service"} className={"image-service"}/>
                        </div>
                    </div>
                </div>
                <div className="section-2">
                    <div className="text-wrapper">
                        <p className="text-1">Services</p>
                        <p className="text-2">Managed services provide comprehensive, managed solutions to companies'
                            information
                            technology needs, allowing them to focus on business growth while increasing operational
                            efficiency and security. By utilizing managed services, companies can free up their
                            internal
                            resources to focus on core business activities that support growth and innovation, while
                            the
                            technical and operational aspects are taken care of by the service provider.</p>
                    </div>
                </div>
            </div>

            <div className="service-content-2-wrapper">
                <div className="text-wrapper">
                    <p className="text-1">“We provide a team of experts and experience in information technology”</p>
                    <p className="text-2">By using this service, companies can access specialized expertise without
                        having to <br/>
                        recruit and manage a large internal team. Operational costs can be more easily <br/>
                        predicted helping companies manage their IT budgets more efficiently.</p>
                </div>
                <div className="img-wrapper">
                    <LazyImage src={laptop} alt={"laptop"} className="laptop-img"/>
                </div>
            </div>

            <div className="service-content-mobile-3-wrapper">
                <LazyImage src={sectionMobileService3} alt={"section-mobile-service3"}/>
                <div className="img-service-wrapper">
                    <div className="img-service">
                        <LazyImage src={relevantImg} alt={"relevant-img"}/>
                        <p>Relevant</p>
                    </div>
                    <div className="img-service">
                        <LazyImage src={securedImg} alt={"secured-img"}/>
                        <p>Secured</p>
                    </div>
                    <div className="img-service">
                        <LazyImage src={advancedImg} alt={"advanced-img"}/>
                        <p>Advanced</p>
                    </div>
                    <p className="title-content">We ensure that the technology used is continuously updated and relevant
                        to business needs. This
                        can reduce the risk of using outdated, unsafe technology. Advanced disaster recovery and data
                        backup solutions, which can help companies protect critical information from loss or damage.</p>
                </div>
            </div>

            <div className="service-content-3-wrapper">
                <div className="absolute top-10 left-0">
                    <LazyImage src={leftCane} alt={"left-cane"}/>
                </div>
                <div className="image-service-wrapper">
                    <div className="img-service">
                        <LazyImage src={relevantImg} alt={"relevant-img"}/>
                        <p>Relevant</p>
                    </div>
                    <div className="img-service">
                        <LazyImage src={securedImg} alt={"secured-img"}/>
                        <p>Secured</p>
                    </div>
                    <div className="img-service">
                        <LazyImage src={advancedImg} alt={"advanced-img"}/>
                        <p>Advanced</p>
                    </div>
                </div>
                <p className="descriptiom-service">We ensure that the technology used is continuously updated and
                    relevant to <br/>business needs. This can reduce the risk of using outdated, unsafe
                    technology. <br/>
                    Advanced disaster recovery and data backup solutions, which can help companies <br/>
                    protect critical information from loss or damage.</p>
                <div className="absolute bottom-0 right-0">
                    <LazyImage src={rightCane} alt={"right-cane"}/>
                </div>
            </div>

            <div className="service-content-4-wrapper">
                <p className="text1">The infrastructure we provide is <br/>
                    designed for high availability and <br/> optimal performance</p>
                <p className="text2">Includes proactive monitoring and routine maintenance to ensure that the
                    system <br/>
                    remains operational at all times.</p>
            </div>

            <div className="service-content-5-mobile-wrapper">
                <LazyImage src={sectionMobileService5} alt={"section-service-5"}/>
            </div>

            <div className="service-content-5-wrapper">
                <LazyImage
                    src={infrastructure}
                    alt={"infrastructure"}
                    className={"infrastructure-img"}/>
            </div>

            <div ref={targetRef} className="service-content-6-wrapper">
                <LazyImage
                    src={talkUs}
                    alt={"talk-with-us"}
                    className={"image-dekstop"}
                />

                <LazyImage
                    src={sectionMobileService6}
                    alt={"section-service-6"}
                    width={"100%"}
                    className={"image-mobile"}
                />

                <div className="call-wrapper">
                    <p>Talk With Us</p>
                    <button onClick={handleInstagramClick}
                            className="button-1">
                        <img src={instagram} alt={"instagram"}/>
                        peentar
                    </button>
                    <button
                        onClick={handlePhoneClick}
                        className="button-2">
                        <img src={call} alt="call"/>
                        (021) 7265907
                    </button>
                    <button
                        onClick={handleEmailClick}
                        className="button-3">
                        <img src={message} alt="message"/>
                        solution@teknologipeentar.com
                    </button>
                </div>

                <div className="call-mobile-wrapper">
                    <p>Talk With Us</p>
                    <button onClick={handleInstagramClick}
                            className="button-1">
                        <img src={instagram} alt={"instagram"}/>
                        peentar
                    </button>
                    <button
                        onClick={handlePhoneClick}
                        className="button-2">
                        <img src={call} alt="call"/>
                        (021) 7265907
                    </button>
                    <button
                        onClick={handleEmailClick}
                        className="button-3">
                        <img src={message} alt="message"/>
                        solution@teknologipeentar.com
                    </button>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default ServicePage;
