import React, {useState} from 'react';
import Navbar from "../../../components/atoms/Navbar";
import {
    bgLinearLeftBottom, bgLinearRightBottom,
    bgLinearRightTop, burgerBlack, knowUsMobile,
    logoPeentar,
} from "../../../assets/svgs";
import {
    activeNavbarStyle,
    activeNavbarStyleWhite,
    fontMenuProductStyle,
    fontMenuProductStyleWhite,
    navbarStyle,
    navbarStyleWhite
} from "../../../plugins/constant/navbar-styles.constant";
import NavbarProduct from "../../../components/atoms/NavbarProduct";
import {useNavigate} from "react-router-dom";
import "./BigData.scss";
import LazyImage from "../../../components/atoms/LazyImage";
import bgBigData from "../../../assets/svgs/products/big-data/background_big_data.png";
import bigData from "../../../assets/svgs/products/big-data/big_data.png";
import analyticsBigData from "../../../assets/svgs/products/big-data/analytics_big_data.png";
import bgBenefitsBigData from "../../../assets/svgs/products/big-data/bg_benefits_big_data.png";
import benefitsBigData from "../../../assets/svgs/products/big-data/benefits_big_data.png";
import efficiency from "../../../assets/svgs/products/big-data/efficiency.png";
import smarter from "../../../assets/svgs/products/big-data/smarter.png";
import productInnovation from "../../../assets/svgs/products/big-data/product_innovation.png";
import opportunities from "../../../assets/svgs/products/big-data/opportunities.png";
import Footer from "../../../components/atoms/Footer";
import inventory from "../../../assets/svgs/products/omnichannel/bg_inventory.png";
import {talkWithUs} from "../../../assets/svgs/about";
import triangleRight from "../../../assets/svgs/products/big-data/triangle_right.png";
import triangleLeft from "../../../assets/svgs/products/big-data/triangle_left.png";
import dataCollection from "../../../assets/svgs/products/big-data/data_collection.png";
import dataStorage from "../../../assets/svgs/products/big-data/data_storage.png";
import dataProcessing from "../../../assets/svgs/products/big-data/data_processing.png";
import dataAnalysis from "../../../assets/svgs/products/big-data/data_analysis.png";
import dataVisualization from "../../../assets/svgs/products/big-data/data_visualization.png";
import bgAnalyzingData from "../../../assets/svgs/products/big-data/bg_analyzing_mobile.png";
import RegularButton from "../../../components/atoms/RegularButton";
import {useScroll} from "../../../components/atoms/ScrollProvider";

function BigData() {
    const navigate = useNavigate();
    const targetRef = useScroll();
    const [changeNavbar, setChangeNavbar] = useState(false);
    const [isCollectionDisplayed, setIsCollectionDisplayed] = useState(true);
    const [isStorageDisplayed, setIsStorageDisplayed] = useState(true);
    const [isProcessingDisplayed, setIsProcessingDisplayed] = useState(true);
    const [isAnalysisDisplayed, setIsAnalysisDisplayed] = useState(true);
    const [isVisualizationDisplayed, setIsVisualizationDisplayed] = useState(true);
    const handleNavigateMenuProduct = (routeMenuProduct: string) => {
        navigate(routeMenuProduct);
    }
    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    const navigateToServicePage = () => {
        navigate("/service-page")
        setTimeout(() => {
            targetRef.current?.scrollIntoView({behavior: 'smooth'});
        }, 100);
    }
    return (
        <div onScroll={scrollEvent}
             className="bigData-page-wrapper">
            <div className="bigData-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={logoPeentar}
                    activeButtonStyle={changeNavbar ? activeNavbarStyle : activeNavbarStyleWhite}
                    buttonStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    fontMenuProductStyle={changeNavbar ? fontMenuProductStyle : fontMenuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarStyle : navbarStyleWhite}
                    iconNavbar={burgerBlack}
                    svgFillColor={changeNavbar ? "#1AA596" : "#000000"}
                    svgFillColorMobile={"#FFFFFF"}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="bigData-header-content-wrapper">
                    <NavbarProduct
                        handleNavigateMenuProduct={handleNavigateMenuProduct}
                    />
                    <div className="bigData-header-content">
                        <div className="content-image">
                            <LazyImage src={bgBigData} alt={"bg-big-data"}/>
                        </div>

                        <div className="content-text">
                            <div className="bigData-title-wrapper">
                                <LazyImage src={bigData} alt={"big-data"}/>
                                <p className="bigData-title">Big Data</p>
                            </div>

                            <div className="bg-big-data-mobile">
                                <LazyImage src={bgBigData} alt={"bg-big-data"}/>
                            </div>

                            <div className="text-wrapper">
                                <p className="text1">Collecting, processing, and analyzing data on a large
                                    scale using specialized tools, techniques, and methods</p>
                                <p className="text2">Big Data Analytics is the process of collecting,
                                    processing, and analyzing data that is very large (volume),
                                    complex (variety), and moving at high speed (velocity) to gain valuable
                                    insights and understanding. Big data analytics uses tools, techniques
                                    and methods specifically designed to process and analyze
                                    data on a very large scale.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bigData-container-1-wrapper">
                <p>The Big Data Analytics system is composed of several <br/> elements as follows:</p>

                <div className="bg-linear-right-top">
                    <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
                </div>

                <div className="analytics-big-data-mobile-wrapper">
                    <LazyImage src={analyticsBigData} alt={"analytics-big-data"}/>
                </div>

                <div className="bg-linear-right-bottom">
                    <LazyImage src={bgLinearRightBottom} alt={"linear-bg"}/>
                </div>

                <div className="bg-linear-left-bottom">
                    <LazyImage src={bgLinearLeftBottom} alt={"linear-bg"}/>
                </div>
            </div>

            <div className="bigData-container-1-mobile-wrapper">
                <p>The Big Data Analytics system is composed of several <br/> elements as follows:</p>

                <div className="bg-linear-right-top">
                    <LazyImage src={bgLinearRightTop} alt={"linear-bg"}/>
                </div>

                <div className="elements-bigData-anaytics-wrapper">
                    {isCollectionDisplayed ? <div
                        onClick={() => setIsCollectionDisplayed(!isCollectionDisplayed)}
                        className="elements-bigData-anaytics-right">
                        <LazyImage src={triangleRight} alt={"triangle-right"}/>
                        <div className="data-right">
                            <div className="text-wrapper">
                                <LazyImage src={dataCollection} alt={"data-collection"}/>
                                <p className="title">Data Collection</p>
                                <p className="text">Big data analytics involves collecting data from various sources,
                                    including sensors, mobile devices, social media,
                                    transaction logs, historical...</p>
                            </div>
                        </div>
                    </div> : <div
                        onClick={() => setIsCollectionDisplayed(!isCollectionDisplayed)}
                        className="elements-bigData-anaytics-right">
                        <LazyImage src={triangleRight} alt={"triangle-right"}/>
                        <div className="data-right-active">
                            <div className="text-wrapper">
                                <p className="text">Big data analytics involves collecting
                                    data from various sources, including sensors, mobile devices,
                                    social media, transaction logs, historical data, and others.
                                    This data often has a variety of complex formats and structures,
                                    structured (transaction data, medical record data, ERP data)
                                    or unstructured (social media, journals, minutes of meetings,
                                    document archives). The system can retrieve data sourced
                                    from Internal Systems and External Systems.
                                </p>
                            </div>
                        </div>
                    </div>}


                    {isStorageDisplayed ? <div
                        onClick={() => setIsStorageDisplayed(!isStorageDisplayed)}
                        className="elements-bigData-anaytics-left">
                        <LazyImage src={triangleLeft} alt={"triangle-left"}/>
                        <div className="data-left">
                            <div className="text-wrapper">
                                <LazyImage src={dataStorage} alt={"data-storage"}/>
                                <p className="title">Data Storage</p>
                                <p className="text">Data in big data analytics is stored in a scalable and
                                    distributed storage infrastructure. Using Data Lake and
                                    Data Warehouse...</p>
                            </div>
                        </div>
                    </div> : <div
                        onClick={() => setIsStorageDisplayed(!isStorageDisplayed)}
                        className="elements-bigData-anaytics-left">
                        <LazyImage src={triangleLeft} alt={"triangle-left"}/>
                        <div className="data-left-active">
                            <div className="text-wrapper">
                                <p className="text">Data in big data analytics is stored in
                                    a scalable and distributed storage infrastructure. Using Data Lake and
                                    Data Warehouse technology which is used
                                    to store and manage large volumes of data.
                                </p>
                            </div>
                        </div>
                    </div>}

                    {isProcessingDisplayed ? <div
                        onClick={() => setIsProcessingDisplayed(!isProcessingDisplayed)}
                        className="elements-bigData-anaytics-right">
                        <LazyImage src={triangleRight} alt={"triangle-right"}/>
                        <div className="data-right">
                            <div className="text-wrapper">
                                <LazyImage src={dataProcessing} alt={"data-processing"}/>
                                <p className="title">Data Processing</p>
                                <p className="text">Data in big data analytics is processed using special
                                    techniques and algorithms. Then include parallel processing, distrib...</p>
                            </div>
                        </div>
                    </div> : <div
                        onClick={() => setIsProcessingDisplayed(!isProcessingDisplayed)}
                        className="elements-bigData-anaytics-right">
                        <LazyImage src={triangleRight} alt={"triangle-right"}/>
                        <div className="data-right-active-processing">
                            <div className="text-wrapper">
                                <p className="text">Data in big data analytics is processed using special
                                    techniques and algorithms. Then include parallel processing,
                                    distributed computing, indexing techniques, and real-time
                                    or streaming data processing.</p>
                            </div>
                        </div>
                    </div>}

                    {isAnalysisDisplayed ? <div
                        onClick={() => setIsAnalysisDisplayed(!isAnalysisDisplayed)}
                        className="elements-bigData-anaytics-left">
                        <LazyImage src={triangleLeft} alt={"triangle-left"}/>
                        <div className="data-left">
                            <div className="text-wrapper">
                                <LazyImage src={dataAnalysis} alt={"data-analysis"}/>
                                <p className="title">Data Analysis</p>
                                <p className="text">Big data analytics involves the use of sophisticated algorithms
                                    and analysis techniques to identify patterns, trends, and
                                    insights contained in...</p>
                            </div>
                        </div>
                    </div> : <div
                        onClick={() => setIsAnalysisDisplayed(!isAnalysisDisplayed)}
                        className="elements-bigData-anaytics-left">
                        <LazyImage src={triangleLeft} alt={"triangle-left"}/>
                        <div className="data-left-active">
                            <div className="text-wrapper">
                                <p className="text">Big data analytics involves the use of sophisticated
                                    algorithms and analysis techniques to identify patterns, trends,
                                    and insights contained in large and complex data.
                                    This includes statistical analysis, predictive analysis,
                                    machine learning and artificial intelligence.</p>
                            </div>
                        </div>
                    </div>}

                    {isVisualizationDisplayed ? <div
                        onClick={() => setIsVisualizationDisplayed(!isVisualizationDisplayed)}
                        className="elements-bigData-anaytics-right">
                        <LazyImage src={triangleRight} alt={"triangle-right"}/>
                        <div className="data-right">
                            <div className="text-wrapper">
                                <LazyImage src={dataVisualization} alt={"data-visualization"}/>
                                <p className="title">Data Visualization</p>
                                <p className="text">After data is processed and analyzed, the
                                    results are often displayed in the form of visualizations that
                                    are easy to understan...</p>
                            </div>
                        </div>
                    </div> : <div
                        onClick={() => setIsVisualizationDisplayed(!isVisualizationDisplayed)}
                        className="elements-bigData-anaytics-right">
                        <LazyImage src={triangleRight} alt={"triangle-right"}/>
                        <div className="data-right-active">
                            <div className="text-wrapper">
                                <p className="text">After data is processed and analyzed, the
                                    results are often displayed in the form of visualizations that are
                                    easy to understand, such as graphs, diagrams,
                                    or maps. This visualization helps make better decisions and faster
                                    understanding of the resulting insights (Data Insight), including, among others:
                                    Industry Trends; Market Intelligence; Brand Awareness;
                                    and Product Performance.</p>
                            </div>
                        </div>
                    </div>}
                </div>

                <div className="bg-linear-left-bottom">
                    <LazyImage src={bgLinearLeftBottom} alt={"linear-bg"}/>
                </div>
            </div>

            <div className="bigData-container-2-wrapper">
                <p>The benefits of big data analytics include <br/> deeper understanding of customers</p>
                <div className="bigData-container-1">
                    <div className="content-1">
                        <LazyImage src={bgBenefitsBigData} alt={"bg-benefits-big-data"}/>
                        <div className="absolute -top-10 left-4">
                            <LazyImage src={benefitsBigData} alt={"benefits-big-data"}/>
                        </div>
                    </div>
                    <div className="content-2">
                        <div className="item">
                            <LazyImage src={efficiency} alt={"efficient"}/>
                            <span>Increased operational efficiency</span>
                        </div>
                        <div className="item">
                            <LazyImage src={smarter} alt={"smarter"}/>
                            <span>Smarter decision making</span>
                        </div>
                        <div className="item">
                            <LazyImage src={productInnovation} alt={"product-innovation"}/>
                            <span>Product innovation</span>
                        </div>
                        <div className="item">
                            <LazyImage src={opportunities} alt={"opportunities"}/>
                            <span>Identification of new business opportunities</span>
                        </div>
                    </div>

                </div>
            </div>

            <div className="bigData-container-3-wrapper">
                <LazyImage src={inventory} alt={"inventory"} width={"100%"} className="inventory"/>
                <div className="absolute">
                    <p className="text1"> By analyzing data at scale</p>
                    <p className="text2">By analyzing data at scale, businesses can gain valuable insights
                        and support fact-based <br/> decision making, which can provide
                        significant competitive advantage and growth.</p>
                </div>
            </div>

            <div className="bigData-container-3-mobile-wrapper">
                <LazyImage src={bgAnalyzingData} alt={"bg-analyzing-data"}
                           width={"100%"} className="bg-analyzing-data-mobile"/>
            </div>

            <div className="bigData-container-4-wrapper">
                <LazyImage src={talkWithUs} alt={"talk with us"} width={"100%"}/>
                <div className="button-wrapper">
                    <RegularButton
                        heightButton={"6vh"}
                        widthButton={"15vw"}
                        backgroundColor={"#FFFFFF"}
                        hoverColor={"#C7EDE9"}
                        fontColor={"#1AA596"}
                        fontWeight={"500"}
                        borderColor={"#FFFFFF"}
                        borderHoverColor={"#1AA596"}
                        borderRadius={"3vw"}
                        iconColor={"#1AA596"}
                        fontSize={"1.1vw"}
                        onClick={navigateToServicePage}
                        label={"Talk With Us"}/>
                </div>
            </div>

            <div className="bigData-container-4-mobile-wrapper">
                <div className="know-us-mobile">
                    <LazyImage src={knowUsMobile} alt={"know-us"} width={"100%"}/>
                    <div className="know-us-title-mobile space-y-4">
                        <p className="text">Want to get to <br/> know us more?</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={navigateToServicePage}
                            label={"Talk With Us"}/>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default BigData;
