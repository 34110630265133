import React, {useRef, useState} from 'react';
import "./HomePage.scss";
import Footer from "../../components/atoms/Footer";
import About from "../../components/organisms/Home/About";
import Service from "../../components/organisms/Home/Service";
import Client from "../../components/organisms/Home/Client";
import Navbar from "../../components/atoms/Navbar";
import RegularButton from "../../components/atoms/RegularButton";
import {useNavigate} from "react-router-dom";
import {burgerBlack, burgerWhite, logoPeentar, logoPeentarWhite} from "../../assets/svgs";
import {
    activeNavbarJobDetailStyle,
    activeNavbarWhiteJobDetailStyle,
    menuProductStyle,
    menuProductStyleWhite,
    navbarJobDetailStyle,
    navbarWhiteJobDetailStyle
} from "../../plugins/constant/navbar-styles.constant";

function HomePage() {
    const navigate = useNavigate();
    const aboutSectionRef = useRef<HTMLDivElement | null>(null);
    const [changeNavbar, setChangeNavbar] = useState(false);

    const scrollToSection1 = () => {
        aboutSectionRef.current?.scrollIntoView({behavior: 'smooth'});
    };

    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }

    return (
        <div onScroll={scrollEvent} className="home-page-wrapper scroll-smooth">
            <div className="header-container bg-cover bg-center">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={changeNavbar ? logoPeentar : logoPeentarWhite}
                    activeButtonStyle={changeNavbar ? activeNavbarJobDetailStyle : activeNavbarWhiteJobDetailStyle}
                    buttonStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle}
                    svgFillColor={changeNavbar ? "#1AA596" : "#FFFFFF"}
                    svgFillColorMobile={"#FFFFFF"}
                    iconNavbar={changeNavbar ? burgerBlack : burgerWhite}
                    fontMenuProductStyle={changeNavbar ? menuProductStyle : menuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle }
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="header-content-wrapper">
                    <div className="basis-3/5"></div>
                    <div className="basis-2/5 space-y-6">
                        <p className="title">Empowering <br/> Smart <br/> Technology</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"12em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={scrollToSection1}
                            label={"Know More"}/>
                    </div>
                </div>
            </div>

            <div ref={aboutSectionRef}>
                <About/>
            </div>
            <Service/>
            <Client/>
            <Footer/>
        </div>
    );
}

export default HomePage;
