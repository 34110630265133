export const jobs = [
    {
        id: 1,
        role: 'Junior Backend Engineer',
        descJob: [
            "Candidate must possess at least a Bachelor's Degree in Computer Science/Information Technology, or equivalent experience, including bootcamps",
            "Has 1-2 years of working experience in the related field.",
            "Proficient in Java and Spring Boot framework.",
            "Basic understanding of RESTful API design, SQL databases (e.g., PostgreSQL, MySQL), and Redis.",
            "Familiarity with cloud platforms (AWS, Azure, Google Cloud) is a plus.",
            "Understanding of CI/CD pipelines and containerization (Docker, Kubernetes) is beneficial.",
            "Knowledge of version control systems (Git) and collaborative workflows.",
            "Basic experience or familiarity with Elasticsearch, Apache Kafka, and event-driven architecture is a plus.",
            "Good personal attitude, self-motivated, and strong communication skills.",
            "Able to work under pressure and manage multiple tasks effectively."
        ],
        jobLevel: "Staff (non-management & non-supervisor",
        workExperience: "1-2 Years",
        jobSpecialization: "Developer/Programmer",
        qualifications: "Bachelor's Degree (S1) or equivalent experience, including bootcamps",
        jobType: "Full-Time",
        companySize: "201 - 500 Employees",
        industry: "Computer/Information Technology/IT-Software",
        time: "14 Days",
        workingDays: "Monday-Friday"
    },
    {
        id: 2,
        role: 'Junior Frontend Engineer',
        descJob: [
            "Candidate must possess at least a Bachelor's Degree in Computer Science/Information Technology, or equivalent experience, including bootcamps",
            "Has 1-2 years of working experience in the related field.",
            "Proficient in JavaScript (ES6) and React framework.",
            "Basic understanding of Single Page Application (SPA) architecture and service workers for offline applications.",
            "Experience with TypeScript is a plus.",
            "Familiarity with version control systems (Git) and collaborative workflows.",
            "Understanding of RESTful APIs and how to integrate frontend with backend services.",
            "Familiarity with modern frontend build pipelines and tools (Webpack, Babel, npm, etc.).",
            "Good personal attitude, self-motivated, and strong communication skills.",
            "Able to work under pressure and manage multiple tasks effectively."
        ],
        jobLevel: "Staff (non-management & non-supervisor)",
        workExperience: "1-2 Years",
        jobSpecialization: "Developer/Programmer",
        qualifications: "Bachelor's Degree (S1) or equivalent experience, including bootcamps",
        jobType: "Full-Time",
        companySize: "201 - 500 Employees",
        industry: "Computer/Information Technology/IT-Software",
        time: "14 Days",
        workingDays: "Monday-Friday"
    },
    {
        id: 3,
        role: 'Senior Backend Engineer',
        descJob: [
            "Candidate must possess at least a Bachelor's Degree in Computer Science/Information Technology, or equivalent experience, including bootcamps",
            "Has 3+ years of working experience in the related field.",
            "Expert in Java and Spring Boot framework.",
            "Strong understanding of RESTful API design, SQL databases (e.g., PostgreSQL, MySQL), and Redis",
            "Extensive experience with cloud platforms (AWS, Azure, Google Cloud).",
            "Proficiency with CI/CD pipelines, containerization (Docker, Kubernetes), and microservices architecture.",
            "Knowledge of version control systems (Git) and collaborative workflows.",
            "In-depth experience with Elasticsearch, Apache Kafka, and event-driven architecture is highly desirable.",
            "Demonstrated leadership skills, good personal attitude, self-motivated, and strong communication skills.",
            "Able to work under pressure and manage multiple tasks effectively."
        ],
        jobLevel: "Senior Staff (non-management & non-supervisor)",
        workExperience: "3+ Years",
        jobSpecialization: "Developer/Programmer",
        qualifications: "Bachelor's Degree (S1) or equivalent experience, including bootcamps",
        jobType: "Full-Time",
        companySize: "201 - 500 Employees",
        industry: "Computer/Information Technology/IT-Software",
        time: "14 Days",
        workingDays: "Monday-Friday"
    },
    {
        id: 4,
        role: 'Senior Frontend Engineer',
        descJob: [
            "Candidate must possess at least a Bachelor's Degree in Computer Science/Information Technology, or equivalent experience, including bootcamps",
            "Has 3+ years of working experience in the related field.",
            "Expert in JavaScript (ES6) and React framework.",
            "Strong understanding of Single Page Application (SPA) architecture and service workers for offline applications.",
            "Proficiency in HTML5, CSS3, and responsive design principles.",
            "Extensive experience with TypeScript.",
            "Extensive experience with version control systems (Git) and collaborative workflows.",
            "In-depth knowledge of RESTful APIs and how to integrate frontend with backend services.",
            "Proficiency with modern frontend build pipelines and tools (Webpack, Babel, npm, etc.).",
            "Demonstrated leadership skills, good personal attitude, self-motivated, and strong communication skills.",
            "Able to work under pressure and manage multiple tasks effectively."
        ],
        jobLevel: "Senior Staff (non-management & non-supervisor)",
        workExperience: "1-2 Years",
        jobSpecialization: "Developer/Programmer",
        qualifications: "Bachelor's Degree (S1) or equivalent experience, including bootcamps",
        jobType: "Full-Time",
        companySize: "201 - 500 Employees",
        industry: "Computer/Information Technology/IT-Software",
        time: "14 Days",
        workingDays: "Monday-Friday"
    }
];
