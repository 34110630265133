import React from 'react';
import {
    bgOpportunities, joinUs,
    kfLabKlinik,
    kimiaFarma,
    kimiaFarmaMobile,
    marvee,
    telkomeSigma
} from "../../../../assets/svgs";
import RegularButton from "../../../atoms/RegularButton";
import "./Client.scss";
import LazyImage from "../../../atoms/LazyImage";
import {useNavigate} from "react-router-dom";

function Client() {
    const navigate = useNavigate();

    return (
        <div className="client-container">
            <p className="client-title">Client</p>
            <div className="client-content">
                <div className="basis-1/5">
                    <div className="img-client">
                        <LazyImage src={kimiaFarma} alt={"kimia-farma-img"}/>
                    </div>
                </div>
                <div className="basis-1/5">
                    <div className="img-client">
                        <LazyImage src={kimiaFarmaMobile} alt={"kimia-farma-mobile-img"}/>
                    </div>
                </div>
                <div className="basis-1/5">
                    <div className="img-client">
                        <LazyImage src={kfLabKlinik} alt={"kf-lab-klinik-img"}/>
                    </div>
                </div>
                <div className="basis-1/5">
                    <div className="img-client">
                        <LazyImage src={marvee} alt={"marvee-img"}/>
                    </div>
                </div>
                <div className="basis-1/5">
                    <div className="img-client">
                        <LazyImage src={telkomeSigma} alt={"telkom-sigma-img"}/>
                    </div>
                </div>
            </div>

            <div className="opportunities-content">
                <div className="opportunities-text">
                    <LazyImage src={bgOpportunities} alt={"bg-opportunities"}
                               width={"100%"}/>
                    <div className="absolute top-20 bottom-20 right-24">
                        <div className="opportunities-title space-y-4">
                            <p>Join us and <br/> create innovation</p>
                            <RegularButton
                                heightButton={"6vh"}
                                widthButton={"14em"}
                                backgroundColor={"#FFFFFF"}
                                hoverColor={"#C7EDE9"}
                                fontColor={"#1AA596"}
                                fontWeight={"500"}
                                borderColor={"#FFFFFF"}
                                borderHoverColor={"#1AA596"}
                                borderRadius={"25px"}
                                iconColor={"#1AA596"}
                                fontSize={"1em"}
                                onClick={() => navigate("/career-page")}
                                label={"See opportunities"}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="opportunities-content-mobile">
                <div className="opportunities-text-mobile">
                    <LazyImage src={joinUs} alt={"join-us"}/>
                    <div className="opportunities-title-mobile space-y-4">
                        <p className="text">Join us and create <br/> innovation</p>
                        <RegularButton
                            heightButton={"6vh"}
                            widthButton={"14em"}
                            backgroundColor={"#FFFFFF"}
                            hoverColor={"#C7EDE9"}
                            fontColor={"#1AA596"}
                            fontWeight={"500"}
                            borderColor={"#FFFFFF"}
                            borderHoverColor={"#1AA596"}
                            borderRadius={"25px"}
                            iconColor={"#1AA596"}
                            fontSize={"1em"}
                            onClick={() => navigate("/career-page")}
                            label={"See opportunities"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Client;
