import React from 'react';
import "./NavbarProduct.scss";
import {useLocation} from "react-router-dom";
import {menuProductNames, routeMenuProducts} from "../../../plugins/constant/routes.constant";

type NavbarProductProps = {
    handleNavigateMenuProduct: (route: string) => void;
}

function NavbarProduct({handleNavigateMenuProduct}: NavbarProductProps) {
    const location = useLocation();
    const isActiveProduct = (route: string): boolean => {
        return location.pathname === route;
    };

    const menuStyle = {
        border: 'none',
    };

    const activeMenuStyle = {
        border: 'none',
        borderTop: '5px solid #1AA596',
    };

    return (
        <div className="navbar-product-wrapper">
            {menuProductNames.map((menu, index) => (
                <div className="cursor-pointer"
                     onClick={() => handleNavigateMenuProduct
                    && handleNavigateMenuProduct(routeMenuProducts[index])}>
                    <p key={index}>
                        {menu}
                        <hr style={isActiveProduct(routeMenuProducts[index])
                            ? activeMenuStyle : menuStyle}
                        />
                    </p>
                </div>
            ))}
        </div>
    );
}

export default NavbarProduct;
