const commonButtonStyle = {
    fontSize: '1.2em',
};
export const activeNavbarJobDetailStyle = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#1AA596',
};
export const activeNavbarWhiteJobDetailStyle = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#FFFFFF',
};

export const navbarJobDetailStyle = {
    ...commonButtonStyle,
    fontWeight: '400',
    color: '#1AA596',
};

export const navbarWhiteJobDetailStyle = {
    ...commonButtonStyle,
    fontWeight: '400',
    color: '#FFFFFF',
};

export const activeNavbarStyle = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#1AA596',
};

export const activeNavbarStyleWhite = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#000000',
};


export const navbarStyle = {
    ...commonButtonStyle,
    fontWeight: '400',
    color: '#1AA596',
};

export const navbarStyleWhite = {
    ...commonButtonStyle,
    fontWeight: '400',
    color: '#000000',
};

export const fontMenuProductStyle = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#1AA596',
};

export const fontMenuProductStyleWhite = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#000000',
};

export const menuProductStyle = {
    ...commonButtonStyle,
    fontWeight: '400',
    color: '#1AA596',
};

export const menuProductStyleWhite = {
    ...commonButtonStyle,
    fontWeight: '400',
    color: '#FFFFFF',
};

export const fontMenuAboutStyle = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#1AA596',
};

export const fontMenuAboutStyleWhite = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#FFFFFF',
};

export const fontMenuAboutStyleActive = {
    ...commonButtonStyle,
    fontWeight: '700',
    color: '#000000',
};

