import React, {SVGProps, useEffect, useRef, useState} from 'react';
import "./Navbar.scss";
import {NavbarProps} from "../../../plugins/interfaces/navbarInterface";
import {useLocation, useNavigate} from "react-router-dom";
import LazyImage from "../LazyImage";
import {burgerBlack, burgerWhite, logoPeentarWhite} from "../../../assets/svgs";
import {
    routeNames,
    menuProductNames,
    routesConstant,
    routeMenuProducts, menuAboutNames, routeMenuAbouts
} from "../../../plugins/constant/routes.constant";

function Navbar({
                    backgroundColor = '',
                    logoPeentar = '',
                    buttonStyle,
                    activeButtonStyle,
                    svgFillColor = '',
                    svgFillColorMobile = '',
                    iconNavbar = '',
                    fontMenuProductStyle,
                    fontMenuAboutStyle,
                    navigateToHomePage
                }: NavbarProps) {

    function IcBaselineKeyboardArrowDown(props: SVGProps<SVGSVGElement>) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
                <path fill={svgFillColor} d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z"></path>
            </svg>
        )
    }

    function IcBaselineKeyboardArrowUp(props: SVGProps<SVGSVGElement>) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
                <path fill={svgFillColor} d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6z"></path>
            </svg>
        )
    }

    function IcBaselineKeyboardArrowDownMobile(props: SVGProps<SVGSVGElement>) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
                <path fill={svgFillColorMobile} d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6l-6-6z"></path>
            </svg>
        )
    }

    function IcBaselineKeyboardArrowUpMobile(props: SVGProps<SVGSVGElement>) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24" {...props}>
                <path fill={svgFillColorMobile} d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6l-6 6z"></path>
            </svg>
        )
    }

    const location = useLocation();
    const isActive = (route: string): boolean => {
        return location.pathname === route;
    };

    const navRef = useRef<HTMLElement>(null);
    const getFontWeight = (route: any) => {
        return location.pathname === route ? '700' : 'normal';
    };

    const showNavbar = () => {
        console.log("klikkkk")
        if (navRef.current) {
            navRef.current.classList.toggle("responsive_nav");
            document.body.classList.toggle("no-scroll");
        }
    };

    useEffect(() => {
        document.body.classList.remove("no-scroll");
    }, [])

    const DropdownItem = ({title, children}: any) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div className="dropdown-item">
                <div className="dropdown-title" onClick={toggleOpen}>
                    {title}
                    <span className="dropdown-arrow">{isOpen ? <IcBaselineKeyboardArrowUpMobile/> :
                        <IcBaselineKeyboardArrowDownMobile/>}</span>
                </div>
                {isOpen && <div onClick={toggleOpen} className="dropdown-children">{children}</div>}
            </div>
        );
    };

    const DropdownMenu = () => {
        const navigate = useNavigate();

        return (
            <div className="dropdown">
                {routeNames.map((name, index) => (
                    name === "Product" ? (
                        <DropdownItem title="Product" key={index}>
                            {menuProductNames.map((productName, subIndex) => (
                                <div
                                    key={subIndex}
                                    className="dropdown-sub-item"
                                    style={{fontWeight: getFontWeight(routeMenuProducts[subIndex])}}
                                    onClick={() => navigate(routeMenuProducts[subIndex])}
                                >
                                    {productName}
                                </div>
                            ))}
                        </DropdownItem>
                    ) : (name === "About" ? (
                                <DropdownItem title="About" key={index}>
                                    {menuAboutNames.map((aboutName, subIndex) => (
                                        <div
                                            key={subIndex}
                                            className="dropdown-sub-item"
                                            style={{fontWeight: getFontWeight(routeMenuAbouts[subIndex])}}
                                            onClick={() => navigate(routeMenuAbouts[subIndex])}>
                                            {aboutName}
                                        </div>
                                    ))}
                                </DropdownItem>) :
                            <div
                                key={index}
                                className="dropdown-main-item"
                                style={{fontWeight: getFontWeight(routesConstant[index])}}
                                onClick={() => navigate(routesConstant[index])}
                            >
                                {name}
                            </div>
                    )
                ))}
            </div>
        );
    };

    const DropdownItemProduct = ({title, children}: any) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div className="dropdown-item">
                <div style={fontMenuProductStyle} className="dropdown-title" onClick={toggleOpen}>
                    {title}
                    <span className="dropdown-arrow">{isOpen ? <IcBaselineKeyboardArrowUp/> :
                        <IcBaselineKeyboardArrowDown/>}</span>
                </div>
                {isOpen && <div onClick={toggleOpen} className="dropdown-children">{children}</div>}
            </div>
        );
    };

    const DropdownItemAbout = ({title, children}: any) => {
        const [isOpen, setIsOpen] = useState(false);

        const toggleOpen = () => {
            setIsOpen(!isOpen);
        };

        return (
            <div className="dropdown-item">
                <div style={fontMenuAboutStyle} className="dropdown-title" onClick={toggleOpen}>
                    {title}
                    <span className="dropdown-arrow">{isOpen ? <IcBaselineKeyboardArrowUp/> :
                        <IcBaselineKeyboardArrowDown/>}</span>
                </div>
                {isOpen && <div onClick={toggleOpen} className="dropdown-children">{children}</div>}
            </div>
        );
    };

    const NavbarWeb = () => {
        const navigate = useNavigate();

        return (
            <div className="menu-dropdown-wrapper">
                {routeNames.map((name, index) => (
                    name === "About" ? (
                        <DropdownItemAbout title="About" key={index}>
                            <div className="dropdown-children-about">
                                {menuAboutNames.map((aboutName, subIndex) => (
                                    <div
                                        key={subIndex}
                                        className="dropdown-sub-item"
                                        style={{fontWeight: getFontWeight(routeMenuAbouts[subIndex])}}
                                        onClick={() => navigate(routeMenuAbouts[subIndex])}
                                    >
                                        {aboutName}
                                    </div>
                                ))}
                            </div>
                        </DropdownItemAbout>
                    ) : (
                        name === "Product" ? (
                            <DropdownItemProduct title="Product" key={index}>
                                <div className="dropdown-children-product">
                                    {menuProductNames.map((productName, subIndex) => (
                                        <div
                                            key={subIndex}
                                            className="dropdown-sub-item"
                                            style={{fontWeight: getFontWeight(routeMenuProducts[subIndex])}}
                                            onClick={() => navigate(routeMenuProducts[subIndex])}
                                        >
                                            {productName}
                                        </div>
                                    ))}
                                </div>
                            </DropdownItemProduct>
                        ) : <div
                            key={index}
                            className="dropdown-main-item"
                            style={isActive(routesConstant[index]) ? activeButtonStyle : buttonStyle}
                            onClick={() => navigate(routesConstant[index])}
                        >
                            {name}
                        </div>
                    )
                ))}
            </div>
        );
    };

    return (
        <>
            {/*dekstop*/}
            <header style={{backgroundColor: backgroundColor}} className="fixed">
                <button onClick={navigateToHomePage}>
                    <LazyImage src={logoPeentar} alt={"peentar-logo"}/>
                </button>
                <NavbarWeb/>
            </header>

            {/*mobile*/}
            <div style={{backgroundColor: backgroundColor}} className="header-mobile">
                <button onClick={navigateToHomePage}>
                    <LazyImage src={logoPeentar} alt={"peentar-logo"}/>
                </button>
                <button onClick={showNavbar}>
                    <LazyImage src={iconNavbar} alt={"burger-white"}/>
                </button>
                <nav ref={navRef}>
                    <div className="header-nav-on">
                        <div className="top-header-nav-on">
                            <button onClick={navigateToHomePage}>
                                <LazyImage src={logoPeentarWhite} alt={"peentar-logo"}/>
                            </button>
                            <button onClick={showNavbar}>
                                <LazyImage src={burgerWhite} alt={"burger-white"}/>
                            </button>
                        </div>
                        <DropdownMenu/>
                    </div>
                </nav>
            </div>
        </>

    );
}

export default Navbar;
