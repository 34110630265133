import React from 'react';
import "./Footer.scss"
import {djkiLogo, phone, mail, instagram, logoPeentarWhite, pseLogo, youtube} from "../../../assets/svgs";
import LazyImage from "../LazyImage";
import {useNavigate} from "react-router-dom";

function Footer() {
    const navigate = useNavigate();
    return (
        <div className="footer-container">
            <div className="footer-content-1">
                <div className="section space-y-3">
                    <p className="menu-title">Company</p>
                    <button onClick={() => navigate("/about/corporate-profile")}>About</button>
                    <button onClick={() => navigate("/client-page")}>Client</button>
                    <button onClick={() => navigate("/service-page")}>Service</button>
                    <button onClick={() => navigate("/career-page")}>Career</button>
                </div>
                <div className="section space-y-3">
                    <p className="menu-title">Product</p>
                    <button onClick={() => navigate("/product/smart-stock")}>Smartstock</button>
                    <button onClick={() => navigate("/product/pos")}>POS</button>
                    <button onClick={() => navigate("/product/omni-channel")}>Omnichannel</button>
                    <button onClick={() => navigate("/product/integrated-healthcare")}>Integrated Healthcare</button>
                    <button onClick={() => navigate("/product/digital-signature")}>Digital Signature</button>
                    <button onClick={() => navigate("/product/customer-loyalty")}>Customer Loyalty</button>
                    <button onClick={() => navigate("/product/big-data")}>Big Data</button>
                </div>
                <div className="section-icon space-y-3">
                    <p className="menu-title">Contact Us</p>
                    <div className="icon-content-1-wrapper">
                        <a href={"tel:+62217265907"} target="_blank" rel="noopener noreferrer">
                            <LazyImage src={phone} alt={"phone"}/>
                            <span>(021) 7265907</span>
                        </a>
                        <a href={"mailto:solution@teknologipeentar.com"} target="_blank" rel="noopener noreferrer">
                            <LazyImage src={mail} alt={"mail"}/>
                            <span>solution@teknologipeentar.com</span>
                        </a>
                        <a href={"https://www.instagram.com/peentar.id"} target="_blank" rel="noopener noreferrer">
                            <LazyImage src={instagram} alt={"instagram"}/>
                            <span>peentar.id</span>
                        </a>
                        <a href={"https://www.youtube.com/@Peentarid"} target="_blank" rel="noopener noreferrer">
                            <LazyImage src={youtube} alt={"youtube"}/>
                            <span>@peentarid</span>
                        </a>
                    </div>

                    <div className="icon-content-2-wrapper">
                        <div className="djki-logo">
                            <LazyImage src={djkiLogo} alt={"djkiLogo"}/>
                            <p>PB-UMKU : 022030410263700010001</p>
                        </div>
                        <div className="pse2-logo">
                            <LazyImage src={pseLogo} alt={"pseLogo"}/>
                            <p>No. Sertifikat : EC00202321574 </p>
                        </div>
                    </div>
                </div>
            </div>

            <hr/>

            <div className="footer-content-2">
                <LazyImage src={logoPeentarWhite} alt={"logo-peentar"}/>
                <p>Copyright © 2024 PT. Harmonix Teknologi Peentar</p>
            </div>
        </div>
    );
}

export default Footer;
