import React, {useState} from 'react';
import "./PeentarValue.scss";
import {useNavigate} from "react-router-dom";
import {burgerBlack, burgerWhite, logoPeentar, logoPeentarWhite} from "../../../assets/svgs";
import {
    activeNavbarJobDetailStyle,
    activeNavbarWhiteJobDetailStyle,
    fontMenuAboutStyle,
    fontMenuAboutStyleWhite,
    menuProductStyle,
    menuProductStyleWhite,
    navbarJobDetailStyle,
    navbarWhiteJobDetailStyle
} from "../../../plugins/constant/navbar-styles.constant";
import Navbar from "../../../components/atoms/Navbar";
import LazyImage from "../../../components/atoms/LazyImage";
import trust from "../../../assets/svgs/about/peentar-value/trust.png";
import responsibilty from "../../../assets/svgs/about/peentar-value/responsibility.png";
import adaptability from "../../../assets/svgs/about/peentar-value/adaptability.png";
import vectorLeft from "../../../assets/svgs/about/peentar-value/vector_left.png";
import vectorRight from "../../../assets/svgs/about/peentar-value/vector_right.png";
import focusImg from "../../../assets/svgs/about/peentar-value/focus_img.png";
import familyImg from "../../../assets/svgs/about/peentar-value/family_img.png";
import bgImg from "../../../assets/svgs/about/peentar-value/background_img.png";
import creativity from "../../../assets/svgs/about/peentar-value/creativity.png";
import customerOriented from "../../../assets/svgs/about/peentar-value/customer_oriented.png";
import Card from "../../../components/atoms/Card/Card";
import Footer from "../../../components/atoms/Footer";

function PeentarValue() {
    const navigate = useNavigate();
    const [changeNavbar, setChangeNavbar] = useState(false);

    const scrollEvent = (e: React.UIEvent<HTMLDivElement>) => {
        if (e.currentTarget.scrollTop > 50) {
            setChangeNavbar(true);
        } else {
            setChangeNavbar(false);
        }
    }
    return (
        <div onScroll={scrollEvent} className="peentar-value-page-wrapper scroll-smooth">
            <div className="peentar-value-header-page">
                <Navbar
                    backgroundColor={changeNavbar ? "#FFFFFF" : ""}
                    logoPeentar={changeNavbar ? logoPeentar : logoPeentarWhite}
                    activeButtonStyle={changeNavbar ? activeNavbarJobDetailStyle : activeNavbarWhiteJobDetailStyle}
                    buttonStyle={changeNavbar ? navbarJobDetailStyle : navbarWhiteJobDetailStyle}
                    svgFillColor={changeNavbar ? "#1AA596" : "#FFFFFF"}
                    svgFillColorMobile={"#FFFFFF"}
                    iconNavbar={changeNavbar ? burgerBlack : burgerWhite}
                    fontMenuProductStyle={changeNavbar ? menuProductStyle : menuProductStyleWhite}
                    fontMenuAboutStyle={changeNavbar ? fontMenuAboutStyle : fontMenuAboutStyleWhite}
                    navigateToHomePage={() => navigate("/")}
                />

                <div className="peentar-value-header-content-wrapper">
                    <div className="peentar-value-header-content">
                        <p className="title-header">Peentar Value</p>
                        <p className="text-header">Unveiling Our <br/>
                            Comprehensive <br/>
                            Corporate Values <br/>
                            Framework</p>
                    </div>
                </div>
            </div>

            <div className="peentar-value-content-1-wrapper">
                <div className="absolute top-0 left-0">
                    <LazyImage src={vectorLeft} alt={"vector_left"}/>
                </div>
                <div className="peentar-value-content">
                    <Card title={"TRUST"}
                          description={"We believe that Trust is a fundamental element " +
                              "in creating strong bonds - both internally and externally. " +
                              "Being trustworthy means always being reliable in " +
                              "providing excellent service to our stakeholders."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"center"}
                          heightCard={"30em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.2em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}
                    >
                        <LazyImage src={trust}
                                   alt={"card-img"}
                                   className="margin-card"/>
                    </Card>

                    <Card title={"RESPONSIBILITY"}
                          description={"Employees should be able to complete assigned tasks and support " +
                              "the Company's commitment to keeping its promises. Demonstrating " +
                              "integrity by efficiently completing tasks and having " +
                              "the confidence to face new challenges along the way."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"center"}
                          heightCard={"30em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.2em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}
                    >
                        <LazyImage src={responsibilty}
                                   alt={"card-img"}
                                   className="margin-card"/>
                    </Card>

                    <Card title={"ADAPTABILITY"}
                          description={"Surviving in the fast-paced technology sector requires the ability " +
                              "to quickly adapt to new circumstances. The faster we adapt " +
                              "to changes, the quicker new opportunities emerge for growth. " +
                              "We believe that Adaptability is crucial for thriving " +
                              "in this ever-evolving industry."}
                          backgroundColor={"#FFFFFF33"}
                          alignItemsCard={"center"}
                          heightCard={"30em"}
                          widthCard={"21em"}
                          colorTitle={"#FFFFFF"}
                          fontSizeTitle={"1.2em"}
                          fontWeightTitle={500}
                          colorContent={"#FFFFFF"}
                          fontSizeContent={"1em"}
                    >
                        <LazyImage src={adaptability}
                                   alt={"card-img"}
                                   className="margin-card"/>
                    </Card>
                </div>

                <div className="absolute bottom-0 right-0">
                    <LazyImage src={vectorRight} alt={"vector_right"}/>
                </div>
            </div>

            <div className="peentar-value-content-2-wrapper">
                <div className="column-1">
                    <p className="text-1">Focus</p>
                    <p className="text-2">Stay focused on work and develop a Growth Mindset to remain motivated.
                        Being focused means having the ability to recognize new opportunities
                        that are meaningful to our customers. Our company sees challenges as
                        opportunities to learn and grow.</p>
                </div>
                <div className="column-2">
                    <LazyImage src={bgImg} alt={"bg-img"}/>
                    <div className="focus-image-wrapper">
                        <LazyImage src={focusImg} alt={"focus_img"} className={"focus-img"}/>
                    </div>
                </div>
            </div>

            <div className="peentar-value-content-3-wrapper">
                <div className="column-1">
                    <LazyImage src={bgImg} alt={"bg-img"}/>
                    <div className="family-image-wrapper">
                        <LazyImage src={familyImg} alt={"family_img"} className={"family-img"}/>
                    </div>
                </div>
                <div className="column-2">
                    <p className="text-1">Family</p>
                    <p className="text-2">Family takes care of each other. Our company believes
                        that everyone should feel valued and respected in the workplace.
                        Creating a sense of belonging where every voice is heard. Having
                        a Family-like culture will foster greater productivity as everyone
                        strives to be the best version of themselves as a team.</p>
                </div>
            </div>

            <div className="peentar-value-content-4-wrapper">
                <div className="column-1">
                    <p className="text-1">Innovation and Creativity</p>
                    <p className="text-2">We believe that Creativity goes hand in hand with Innovation.
                        Without creativity, there would be no innovation. We encourage creative
                        problem-solving skills to facilitate the development of innovative solutions.
                        These values will help the Company to thrive in today's highly dynamic world.</p>
                </div>
                <div className="column-2">
                    <LazyImage src={bgImg} alt={"bg-img"}/>
                    <div className="creativity-image-wrapper">
                        <LazyImage src={creativity} alt={"creativity_img"} className={"creativity-img"}/>
                    </div>
                </div>
            </div>

            <div className="peentar-value-content-5-wrapper">
                <div className="column-1">
                    <LazyImage src={bgImg} alt={"bg-img"}/>
                    <div className="customer-oriented-image-wrapper">
                        <LazyImage
                            src={customerOriented}
                            alt={"customer_oriented_img"}
                            className={"customer-oriented-img"}/>
                    </div>
                </div>
                <div className="column-2">
                    <p className="text-1">Customer-oriented</p>
                    <p className="text-2">Customer satisfaction is our top priority. We aim to always be
                        responsive to serve the needs, suggestions, and complaints of our
                        customers at any time throughout the day. Providing the best quality
                        solutions to maintain our reliability as a pioneer in the Healthcare
                        Information Systems Company in Indonesia.</p>
                </div>
            </div>

            <Footer/>
        </div>
    );
}

export default PeentarValue;
